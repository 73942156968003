import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MenuBox from "../components/common/MenuBox";
import CustomerDetailTab from "../components/module-tabs-detail-page/CustomerDetailTab";
import axios from "axios";
import { useQuery } from "react-query";
import Loader from "../components/common/Loader";

function CustomerDetail() {
  const location = useLocation();
  const customerDetails = location.state || JSON.parse(sessionStorage.getItem("currentCustomer")) || {};
  const [data, setData] = useState(null);
  const fetchCustomerDetails = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}order/getordersof${location.pathname}`);
    setData(response.data);
    return response.data;
  };

  useEffect(() => {
    fetchCustomerDetails();
  }, [location.pathname]);

  const allJobs = data?.data?.flatMap((order) => order.jobs)
  console.log(customerDetails)
  return (
    <section className="main-dashboard-section">
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox />
          {data == null &&
            <Loader />
          }
          {data != null && (
            <CustomerDetailTab
              customerName={customerDetails.customerName}
              totalOrder={customerDetails?.orderCount || 0}
              totalAmountSpend={customerDetails?.totalAmount}
              customer={customerDetails?.customer}
              orders={customerDetails?.orders}
              allJobs={allJobs}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default CustomerDetail;
