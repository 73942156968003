import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ctaArrow from "../../assets/img/cta-arrow.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import changeImg from "../../assets/img/user-change-img.webp";
import itemEditBtn from "../../assets/img/item-edit-btn.webp";
import serviceArrow from "../../assets/img/service-arrow.webp";
import { useDispatch } from "react-redux";
import {
  deleteItemAction,
  updateItemAction,
} from "../../Redux/Action/itemAction";
import itemDelBtn from "../../assets/img/item-del-btn.webp";

function ItemTab({ title, value }) {
  return (
    <div className="order-detail-box">
      <h6>{title}</h6>
      <h4>{value}</h4>
    </div>
  );
}

function Item({ item, refetch }) {
  const dispatch = useDispatch();
  const { itemIcon, itemName, associatedServices, addedOn } = item;

  const [showMainPopup, setShowMainPopup] = useState(false);
  const [popupClass, setPopupClass] = useState(
    "popup-box user-edit-popup item-popup"
  );
  const [userImg, setUserImg] = useState("");
  const [newEditName, setNewEditName] = useState("");

  const openMainPopup = () => {
    setShowMainPopup(true);
  };

  const closeMainPopup = () => {
    setShowMainPopup(false);
    setPopupClass("popup-box user-edit-popup item-popup");
  };

  const openItemEditPopup = () => {
    setPopupClass("popup-box user-edit-popup item-popup item-edit-popup");
  };

  const handleNameChange = (e) => {
    setNewEditName(e.target.value);
  };
  const openItemDelPopup = () => {
    setPopupClass(
      "popup-box user-edit-popup item-popup fixer-popup item-del-popup"
    );
  };
  const updateFixerHandler = () => {
    dispatch(updateItemAction(item.id, { name: newEditName }));
    closeMainPopup();
  };
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowMainPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return (
    <li>
      <Link
        onClick={openMainPopup}
        className="order-box flex-box align-center space-between"
      >
        <div className="order-detail-box">
          <div className="item-icon-box">
            <img src={itemIcon} width={50} height={50} alt="" />
          </div>
        </div>
        <ItemTab title="Item Name" value={itemName} />
        <ItemTab title="Added On" value={addedOn} />
        <div className="order-cta-box">
          <img src={ctaArrow} alt="" />
        </div>
      </Link>
      {showMainPopup && (
        <div className="popup-outer-box">
          <div ref={popupRef} className={popupClass}>
            <button
              style={{ display: "none" }}
              className="popup-close-btn"
              onClick={closeMainPopup}
            >
              <img src={popupCloseIcon} alt="" />
            </button>
            <div className="edit-and-delete-btn flex-box align-center">
              <img
                className="item-edit-btn"
                src={itemEditBtn}
                onClick={openItemEditPopup}
                alt=""
              />
              <img
                className="item-del-btn"
                src={itemDelBtn}
                onClick={openItemDelPopup}
                alt=""
              />
            </div>
            <div className="popup-edit-box flex-box space-between">
              <div className="img-change-box relative">
                <img
                  className="user-edit-img"
                  src={userImg || itemIcon}
                  alt=""
                />
                <img
                  className="user-change-img"
                  style={{ display: "none" }}
                  src={changeImg}
                  alt=""
                  onClick={() => document.getElementById("file-input").click()}
                />
                <input
                  id="file-input"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        const imageDataUrl = event.target.result;
                        setUserImg(imageDataUrl);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
              </div>
              <div className="user-text-box">
                <div className="field-box">
                  <h6>Item Name</h6>
                  <h4 className="edit-name">{itemName}</h4>
                  <input
                    style={{ display: "none" }}
                    className="popup-input"
                    value={newEditName}
                    onChange={handleNameChange}
                    type="text"
                    placeholder={itemName}
                  />
                </div>
                <div className="field-box">
                  <h6>Modified On</h6>
                  <h4>{addedOn}</h4>
                </div>
              </div>
            </div>
            <div
              className="bottom-save-box flex-box space-between align-center"
              style={{ display: "none" }}
            >
              <button
                className="save-info-btn"
                onClick={() => updateFixerHandler()}
              >
                Save
              </button>
            </div>
            <div
              className="confirm-delete-box flex-box space-between align-center"
              style={{ display: "none" }}
            >
              <h4>Are you sure you want to delete this ?</h4>
              <div className="yes-no-tab flex-box align-center">
                <button
                  onClick={() => {
                    dispatch(deleteItemAction(item.id));
                  }}
                >
                  Yes
                </button>
                <button onClick={closeMainPopup}>No</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
}

export default Item;
