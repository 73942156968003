import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ctaArrow from "../../assets/img/cta-arrow.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import itemEditBtn from "../../assets/img/service-edit-btn.webp";
import greenTick from "../../assets/img/green-tick.webp";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteServiceAction,
  updateServiceAction,
} from "../../Redux/Action/serviceAction";
import itemDelBtn from "../../assets/img/item-del-btn.webp";

function ServiceTab({ title, value }) {
  return (
    <div className="order-detail-box">
      <h6>{title}</h6>
      <h4>{typeof value === "object" ? value.join(", ") : value}</h4>
    </div>
  );
}

function Service({ service, deleteItem, setServices }) {
  const [selectedItems, setSelectedItems] = useState(
    () => service?.serviceItemList
  );
  const dispatch = useDispatch();
  const allItem = useSelector((state) => state.itemReducer.allItem);
  const [pinItemVideoData, setpinItemVideoData] = useState(
    service.pin_item_video
  );
  const { serviceName, category, itemsAssociated, addedOn, service_type } =
    service;
  const [showMainPopup, setShowMainPopup] = useState(false);
  const [popupClass, setPopupClass] = useState(
    "popup-box user-edit-popup service-popup"
  );
  const [newStandardCost, setNewStandardCost] = useState(service.standardCost);
  const [newPremiumCost, setNewPremiumCost] = useState(service?.premiumCost);
  const [premiumNewValue, setPremiumNewValue] = useState(service.isPremium);
  const [standardNewValue, setStandardNewValue] = useState(service.isStandard);
  const [newPinItem, setNewPinItem] = useState(service.modeOfInfo.pinItems);
  const [newMatchItem, setNewMatchItem] = useState(
    service.modeOfInfo.matchItems
  );
  const [newMeasureItem, setNewMeasureItem] = useState(
    service.modeOfInfo.measureItems
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(
    () => service?.serviceItemList
  );
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const allItems = allItem;

  const openMainPopup = () => {
    setShowMainPopup(true);
  };
  const closeMainPopup = () => {
    setShowMainPopup(false);
    setPopupClass("popup-box user-edit-popup service-popup");
  };
  const openItemEditPopup = () => {
    setPopupClass("popup-box user-edit-popup service-popup service-edit-popup");
  };
  const handleDeleteItem = (itemName) => {
    setSelectedItems(
      selectedItems.filter((item) => item.serviceItemName != itemName)
    );
  };
  const handleChangeIsPremium = (value) => {
    setPremiumNewValue(value);
  };
  const handleChangeIsStandard = (value) => {
    setStandardNewValue(value);
  };
  const handleStandardCostChange = (e) => {
    setNewStandardCost(e.target.value);
  };
  const handlePremiumCostChange = (e) => {
    setNewPremiumCost(e.target.value);
  };
  const handlePinItem = (value) => {
    setNewPinItem(newPinItem === "Pin Items" ? "" : "Pin Items");
  };
  const handleMatchItem = (value) => {
    setNewMatchItem(newMatchItem === "Match Items" ? "" : "Match Items");
  };
  const handleMeasureItem = (value) => {
    setNewMeasureItem(
      newMeasureItem === "Measure Items" ? "" : "Measure Items"
    );
  };
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);

    setIsOpen(true);
  };

  const handleOptionClick = (name, icon) => {
    if (!selectedItems?.some((item) => item.name === name)) {
      if (
        selectedItems.filter((item) => item.serviceItemName == name).length <= 0
      ) {
        setSelectedItems([
          ...selectedItems,
          { serviceItemName: name, serviceItemIcon: icon },
        ]);
      }
    }
    setSearchTerm("");
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const openItemDelPopup = () => {
    setPopupClass(
      "popup-box user-edit-popup item-popup fixer-popup item-del-popup"
    );
  };

  const updateServiceHandler = () => {
    const infoMode = [];
    if (newPinItem) infoMode.push(newPinItem);
    if (newMatchItem) {
      infoMode.push(newMatchItem);
    }
    if (newMeasureItem) {
      infoMode.push(newMeasureItem);
    }

    dispatch(
      updateServiceAction(service.id, {
        name: serviceName,
        items: selectedItems.map((item) => ({
          name: item.serviceItemName || item.name,
          icon: item.image,
        })),
        is_premium: premiumNewValue === "Yes" ? true : false,
        premium_price: newPremiumCost,
        is_standard: standardNewValue === "Yes" ? true : false,
        standard_price: newStandardCost,
        info_mode: infoMode,
        pin_item_video: pinItemVideoData,

        service,
      })
    );
    closeMainPopup();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <li>
      <Link
        onClick={openMainPopup}
        className="order-box flex-box align-center space-between" style={{flexWrap : "nowrap"}}
      >
        <ServiceTab title="Service Name" value={serviceName} />
        <ServiceTab title="Service Type" value={service_type} />
        <ServiceTab title="Category" value={category} />
        <ServiceTab title="Items Associated" value={itemsAssociated} />
        <ServiceTab title="Added On" value={addedOn} />
        <div className="order-cta-box">
          <img src={ctaArrow} alt="" />
        </div>
      </Link>
      {showMainPopup && (
        <div className="popup-outer-box">
          <div className={popupClass}>
            <button className="popup-close-btn" onClick={closeMainPopup}>
              <img src={popupCloseIcon} alt="" />
            </button>
            <div className="edit-and-delete-btn flex-box align-center">
              <img
                className="item-edit-btn"
                src={itemEditBtn}
                onClick={openItemEditPopup}
                alt=""
              />
              <img
                className="item-del-btn"
                src={itemDelBtn}
                onClick={openItemDelPopup}
                alt=""
              />
            </div>
            <div className="popup-name-box">
              <div className="field-box">
                <h6>Service Name</h6>
                <h4 className="service-title">{serviceName}</h4>
                <h6 className="green-box">Modified On : {addedOn}</h6>
              </div>
            </div>
            <div className="popup-item-list-box relative">
              <div className="add-new-associated-box">
                <div className="search-select" ref={wrapperRef}>
                  <input
                    type="text"
                    placeholder="Search Items"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={() => setIsOpen(true)}
                  />
                  {isOpen && (
                    <ul>
                      {allItem?.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              handleOptionClick(item.name, item.image)
                            }
                          >
                            {item.name}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
              <h6>Associated Items</h6>
              <div className="popup-item-list flex-box">
                {selectedItems?.map((item, index) => (
                  <div className="popup-item" key={index}>
                    {console.log(item, "sfsdfsdgf")}
                    <div className="item-img-box relative">
                      <img
                        className="service-item-icon"
                        src={item.serviceItemIcon}
                        alt={""}
                      />
                      <img
                        className="delete-item-btn"
                        alt=""
                        src={popupCloseIcon}
                        onClick={() => handleDeleteItem(item.serviceItemName)}
                      />
                    </div>
                    <h6>{item.serviceItemName}</h6>
                  </div>
                ))}
              </div>
            </div>
            <div className="popup-type-box">
              <div
                className="left-box flex-box"
                style={{ justifyContent: "space-between" }}
              >
                <h4>Service Type</h4>
                <h6>{service.service_type}</h6>
              </div>
              <div className="type-box flex-box align-center space-between">
                <div className="left-box flex-box align-center">
                  <h4>Is Premium</h4>
                  <h6>{service.isPremium}</h6>
                  <div className="yes-no-checkbox" style={{ display: "none" }}>
                    <label
                      className={
                        premiumNewValue === "Yes" ? "box-selected" : ""
                      }
                    >
                      <input
                        type="checkbox"
                        checked={premiumNewValue === "Yes"}
                        onChange={() => handleChangeIsPremium("Yes")}
                      />
                      <span className="checkbox-custom"></span>
                      Yes
                    </label>
                    <label
                      className={premiumNewValue === "No" ? "box-selected" : ""}
                    >
                      <input
                        type="checkbox"
                        checked={premiumNewValue === "No"}
                        onChange={() => handleChangeIsPremium("No")}
                      />
                      <span className="checkbox-custom"></span>
                      No
                    </label>
                  </div>
                </div>
                <div>
                  <h4 className="bold">{service.premiumCost}</h4>
                  <input
                    style={{ display: "none" }}
                    className="popup-input"
                    value={newPremiumCost}
                    onChange={handlePremiumCostChange}
                    type="text"
                    placeholder={service.premiumCost}
                  />
                </div>
              </div>
              <div className="type-box flex-box align-center space-between">
                <div className="left-box flex-box align-center">
                  <h4>Is Standard</h4>
                  <h6>{service.isStandard}</h6>
                  <div className="yes-no-checkbox" style={{ display: "none" }}>
                    <label
                      className={
                        standardNewValue === "Yes" ? "box-selected" : ""
                      }
                    >
                      <input
                        type="checkbox"
                        checked={standardNewValue === "Yes"}
                        onChange={() => handleChangeIsStandard("Yes")}
                      />
                      <span className="checkbox-custom"></span>
                      Yes
                    </label>
                    <label
                      className={
                        standardNewValue === "No" ? "box-selected" : ""
                      }
                    >
                      <input
                        type="checkbox"
                        checked={standardNewValue === "No"}
                        onChange={() => handleChangeIsStandard("No")}
                      />
                      <span className="checkbox-custom"></span>
                      No
                    </label>
                  </div>
                </div>
                <div>
                  <h4 className="bold">{service.standardCost}</h4>
                  <input
                    style={{ display: "none" }}
                    className="popup-input"
                    value={newStandardCost}
                    onChange={handleStandardCostChange}
                    type="text"
                    placeholder={service.standardCost}
                  />
                </div>
              </div>
              <div className="type-box">
                <div className="left-box flex-box align-center">
                  <h4>Mode of info</h4>
                  <div className="mode-of-info flex-box align-center">
                    {service.modeOfInfo.pinItems === "Pin Items" && (
                      <>
                        <h6 className="flex-box align-center">
                          <span>
                            <img src={greenTick} alt="" />
                          </span>{" "}
                          {service.modeOfInfo.pinItems}
                        </h6>
                      </>
                    )}
                    {service.modeOfInfo.matchItems === "Match Items" && (
                      <h6 className="flex-box align-center">
                        <span>
                          <img src={greenTick} alt="" />
                        </span>{" "}
                        {service.modeOfInfo.matchItems}
                      </h6>
                    )}
                    {service.modeOfInfo.measureItems === "Measure Items" && (
                      <h6 className="flex-box align-center">
                        <span>
                          <img src={greenTick} alt="" />
                        </span>{" "}
                        {service.modeOfInfo.measureItems}
                      </h6>
                    )}
                  </div>
                  <div
                    style={{ display: "none" }}
                    className="yes-no-checkbox mode-of-info-edit"
                  >
                    <label
                      className={
                        newPinItem === "Pin Items" ? "box-selected" : ""
                      }
                    >
                      <input
                        type="checkbox"
                        checked={newPinItem === "Pin Items"}
                        onChange={() => handlePinItem("Pin Items")}
                      />
                      <span className="checkbox-custom"></span>
                      Pin Items
                    </label>
                    <label
                      className={
                        newMatchItem === "Match Items" ? "box-selected" : ""
                      }
                    >
                      <input
                        type="checkbox"
                        checked={newMatchItem === "Match Items"}
                        onChange={() => handleMatchItem("Match Items")}
                      />
                      <span className="checkbox-custom"></span>
                      Match Items
                    </label>
                    <label
                      className={
                        newMeasureItem === "Measure Items" ? "box-selected" : ""
                      }
                    >
                      <input
                        type="checkbox"
                        checked={newMeasureItem === "Measure Items"}
                        onChange={() => handleMeasureItem("Measure Items")}
                      />
                      <span className="checkbox-custom"></span>
                      Measure Items
                    </label>
                  </div>
                </div>
              </div>
              <div
                className="flex-box"
                style={{
                  marginTop: "1rem",
                  marginBottom: "2rem",
                  flexDirection: "column",
                }}
              >
                <label className={"box-selected"}>
                  <h4>Pin Item Video</h4>
                  <input
                    type="text"
                    style={{ width: "100%" }}
                    className="popup-input full-width"
                    placeholder="e.g. Pin items video"
                    value={pinItemVideoData}
                    onChange={(e) => setpinItemVideoData(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div
              className="bottom-save-box "
              style={{ display: "none" }}
              onClick={() => updateServiceHandler()}
            >
              <button className="save-info-btn">Save</button>
            </div>
            <div
              className="confirm-delete-box flex-box space-between align-center"
              style={{ display: "none" }}
            >
              <h4>Are you sure you want to delete this ?</h4>
              <div className="yes-no-tab flex-box align-center">
                <button
                  onClick={() => {
                    dispatch(deleteServiceAction(service.id));
                    setShowMainPopup(false);
                  }}
                >
                  Yes
                </button>
                <button onClick={closeMainPopup}>No</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
}
export default Service;
