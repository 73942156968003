import React, { useState, useEffect } from "react";
import jobDetailIcon from "../../assets/img/job-detail-icon.webp";
import jobStatusIcon from "../../assets/img/job-status-fixer-icon.webp";
import ordersIcon from "../../assets/img/menu-icon-1.webp";
import collectionDetailIcon from "../../assets/img/collection-detail-icon.webp";
import deliveryDetailIcon from "../../assets/img/delivery-detail-icon.webp";
import paymentStatusIcon from "../../assets/img/payment-status-icon.webp";
import dropDownIcon from "../../assets/img/dropdown-icon.webp";
import videoThumbnail from "../../assets/img/video-thumbnail.webp";
import imageAtCollection from "../../assets/img/img-at-collection.webp";
import imageNotFound from "../../assets/img/img-not-found.webp";
import ediitUsersImg from "../../assets/img/item-edit-btn.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import assignTailorImg from "../../assets/img/assign-tailor-img.webp";
import assignTailorImgTwo from "../../assets/img/assign-tailor-img-2.webp";
import assignTailorImgThree from "../../assets/img/assign-tailor-img-3.webp";
import assignTailorImgFour from "../../assets/img/assign-tailor-img-4.webp";
import assignTailorImgFive from "../../assets/img/assign-tailor-img-5.webp";
import assignTailorImgSix from "../../assets/img/assign-tailor-img-6.webp";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export const calculatePickupDate = (deliveryDate) => {
  const deliveryDay = new Date(deliveryDate).getDay(); // 0 represents Sunday, 1 represents Monday, 3 represents Wednesday
  const pickupOffset = deliveryDay === 1 ? 10 : deliveryDay === 3 ? 12 : 0; // Offset based on delivery day
  const pickupDate = new Date(deliveryDate);
  pickupDate.setDate(pickupDate.getDate() + pickupOffset); // Add offset to delivery date
  return pickupDate.toISOString(); // Convert to ISO format for consistency
};
function ItemDetail({
  data,
  tailors,
  data1,
  data2,
  refetch,
  count,
  showDetails,
}) {
  const [additionalDetails, setAdditionalDetails] = useState(null);
  const [showAssignPopup, setShowAssignPopup] = useState();
  const [assignValue, setAssignValue] = useState();
  const tobedeliverdate = calculatePickupDate(data1.deliveryDate);
  const [progressStatus, setProgressStatus] = useState("");
  
  const openAssignPopup = () => {
    setShowAssignPopup(!showAssignPopup);
  };
  const handleAssignValue = async (tailorName, id) => {
    setAssignValue(tailorName);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}order/assign/fixer`,
      {
        id: id,
        oid: data1?._id,
        itemId: data?._id,
      }
    );
    refetch();
  };

  const [additionDetails, setAdditionDetails] = useState(false);
  let percentage = 10.14;
  let totalTax = (percentage / 100) * parseFloat(data?.price);
  let handleSaveAdditionalInfo = async (id, jid) => {
    let res = await axios.put(
      `${process.env.REACT_APP_API_URL}fixer/updateadditionalinfo/${id}?jobId=${jid}`,
      {
        additional_info: additionalDetails,
      }
    );
    if (res.status == 200) {
      toast.success("Updated Successfully!");
      setAdditionDetails(!additionDetails);
      refetch();
    }
  };

  const statuses = [
    { value: "null", label: "Choose" },
    { label: "Assigned", value: "Assigned" },
    { label: "Item Received/Picked", value: "Item Received/Picked" },
    { label: "In Progress", value: "In Progress" },
    { label: "Fixed But Not Dropped", value: "Fixed But Not Dropped" },
    { label: "Fixed, Dropped & Completed", value: "Fixed, Dropped & Completed" },
    { label: "Need More Info", value: "Need More Info" },
    { label: "On Hold or Dispute", value: "On Hold or Dispute" },
    { label: "Was Cancelled By Admin", value: "Was Cancelled By Admin" },
  ];

  const [isChanged, setIsChanged] = useState(false)
  const navigate = useNavigate()
  const handleSubmit = async () => {
    if (progressStatus != null && progressStatus != "") {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}order/activeoronholdjob/${data?._id}`, { status: progressStatus });
      if (res.status == 200) {
        toast.success("Updated Successfully!")
        // navigate(`/${data1?.status}`)
      }
    } else {
      toast.success("Error occured!")
    }
  }

  useEffect(() => {
    setProgressStatus(data?.status || "Assigned")
  }, [data])

  let selectedStatus = statuses.filter((itm) => itm.value == progressStatus)

  return (
    <li className="item-detail-box">
      <div className="head-box flex-box space-between align-center">
        <h6 className="f-20">
          <h4>Service Name :- {data?.service_details?.name} </h4>
          <span
            className={`unassigned 
            ${data?.assign_status === "Not Assigned"
                ? "clr-red"
                : data?.assign_status === "Assigned"
                  ? "clr-green"
                  : ""
              }
            `}
          >
            {data?.assign_status}
          </span>
        </h6>
      </div>
      <ul
        className={`item-detail-inner-box-listing flex-box ${showDetails ? "" : "show"
          }`}
      >
        <li className="item-detail-inner-box">
          <h6 className="tab-detail-heading">
            {" "}
            <span>
              <img src={jobDetailIcon} alt="" />
            </span>{" "}
            Job Details
          </h6>
          <div className="field-box">
            <h6>Service Category</h6>
            <h4>
              {/* {console.log(data)} */}
              {data?.service_details?.selectedService} -{" "}
              {data?.item_details?.pricing_plan == "Standard"
                ? "Standard"
                : "Premium"}
              {/* {data1?.services?.type}, {data1?.plan} */}
            </h4>
          </div>
          <div className="field-box">
            <h6>Item</h6>
            <h4>{data?.item_details?.name}</h4>
          </div>
          <div className="field-box">
            <h6>Service Name</h6>
            <h4>{data?.service_details?.selectedOption}</h4>
          </div>
          <div className="field-box">
            <h6>Job Description</h6>
            <h4>
              {data?.item_details?.describe != undefined
                ? data?.item_details?.describe
                : data?.item_details?.description}
            </h4>
          </div>
          {/* alterationDetails */}
          {data?.service_details?.selectedService == "Alteration" && (
            <div className="field-box">
              <h6>Alteration Details</h6>
              <h4>
                {data?.service_details?.alterationDetails?.type} :-{" "}
                {data?.service_details?.alterationDetails?.measurement}
              </h4>
            </div>
          )}
          <div className={"field-box"}>
            <h6>Description</h6>
            {data?.service_details?.selectedService === "Repairing" && (
              <>
                Repair Location :-{" "}
                {data?.service_details?.repair_details?.location},
                <br />
                <p>
                  Repair Description :-{" "}
                  {data?.service_details?.repair_details?.description}
                </p>
              </>
            )}
            {data?.service_details?.selectedService === "Alteration" && (
              <>Notes to Tailors:- {data?.service_details?.item_notes}</>
            )}
          </div>
          {/* <div className="field-box">
            <h6>Job Instructions Video</h6>
            <video poster={videoThumbnail} src=""></video>
          </div> */}
        </li>

        <li className="item-detail-inner-box">
          <h6 className="tab-detail-heading">
            {" "}
            <span>
              <img src={jobStatusIcon} alt="" />
            </span>{" "}
            Job Status Fixer
          </h6>
          <div className="field-box relative">
            {data?.fixer_id === null && (
              <>
                <h6>Assigned To</h6>
                <div className="assign-box" onClick={openAssignPopup}>
                  <input
                    className="popup-input"
                    value={assignValue}
                    placeholder="Search.."
                  />
                </div>
              </>
            )}
            {showAssignPopup && (
              <div className="popup-outer-box">
                <div className="popup-box user-edit-popup item-popup assign-popup">
                  <button
                    className="popup-close-btn"
                    onClick={() => setShowAssignPopup(!showAssignPopup)}
                    style={{ top: "0rem", right: "0rem" }}
                  >
                    <img src={popupCloseIcon} />
                  </button>
                  <div className="popup-edit-box flex-box space-between">
                    {tailors.map((tailor, index) => (
                      <div
                        key={index}
                        className="assign-tailor-box flex-box align-center"
                        onClick={() => {
                          handleAssignValue(tailor?.name, tailor?._id);
                          setShowAssignPopup(!showAssignPopup);
                        }}
                      >
                        <div className="img-box">
                          <img src={tailor?.image} alt="" />
                        </div>
                        <div className="name-box">
                          <h6>FXRY TAILOR</h6>
                          <h4>{tailor?.name}</h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {/* <button className="assign-btn save-info-btn">Save</button> */}
          </div>
          {data?.fixer_id != null && (
            <>
              <div className="field-box">
                <h6>Change Fixer</h6>
                <div className="assign-box" onClick={openAssignPopup}>
                  <input
                    className="popup-input"
                    value={assignValue}
                    placeholder="Search.."
                  />
                </div>
              </div>
              <div className="field-box">
                <h6>Assigned To</h6>
                <h4>{data?.fixer_details?.name || "NA"}</h4>
              </div>
              <div className="field-box">
                <h6>Completion Date</h6>
                <h4>{data?.fixer_details?.completeion_date || "NA"}</h4>
              </div>
              <div className="field-box">
                <h6>Job Status</h6>
                <h4>
                  {data?.status == "Open" ? "Assigned" : data?.status}
                </h4>
              </div>
              <div className="field-box">
                <h6>Additional Details</h6>
                {data?.fixer_details?.additional_info != " " &&
                  additionDetails == false && (
                    <>
                      <h4>{data?.fixer_details?.additional_info}</h4>
                      <img
                        src={ediitUsersImg}
                        alt=""
                        style={{ width: "1.6rem", cursor: "pointer" }}
                        onClick={() => setAdditionDetails(!additionDetails)}
                      />
                    </>
                  )}
                {data?.fixer_details?.additional_info == " " ||
                  (additionDetails == true && (
                    <>
                      <textarea
                        rows={3}
                        cols={2}
                        style={{ height: "80px", width: "270px" }}
                        onChange={(e) => setAdditionalDetails(e.target.value)}
                        defaultValue={data?.fixer_details?.additional_info}
                      ></textarea>
                      <button
                        className="add-new-btn"
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          border: "white",
                        }}
                        type="button"
                        onClick={() =>
                          handleSaveAdditionalInfo(data?.fixer_id, data?._id)
                        }
                      >
                        Save
                      </button>
                    </>
                  ))}
              </div>
              <div className="field-box">
                <h6>Fixer Details </h6>
                <h4>
                  {data?.fixer_details?.speciality?.map((it) => (
                    <>{it + ","}</>
                  ))}
                </h4>
              </div>
            </>
          )}
        </li>

        <li className="item-detail-inner-box">
          <h6 className="tab-detail-heading">
            {" "}
            <span>
              <img src={ordersIcon} alt="" />
            </span>{" "}
            Sub - Order Details
          </h6>
          <div className="field-box">
            <h6>Sub Order ID</h6>
            <h4>{data?.order_id_custom}</h4>
          </div>
          <div className="field-box">
            <h6>Order Status</h6>
            <h4>{data1?.status}</h4>
          </div>
          <div className="field-box">
            <h6>Barcode </h6>
            <h4>-----------------</h4>
          </div>
          <div className="job-day-left-box pt-0 mt-0">
            <h6>MARK AS</h6>
            <select
              onChange={(e) => {
                setIsChanged(true)
                setProgressStatus(e.target.value)
              }}
            >
              {statuses.map((status) => {
                return (
                  <option
                    key={status.value}
                    value={status.value}
                    selected={data?.job_status == status.value ? true : false}
                  >
                    {status.label}
                  </option>
                )
              })}
            </select>
            {data?.job_status &&
              <h6 style={{marginTop : "0.5rem", marginBottom : "1rem"}}>{data?.job_status} - {new Date(data?.job_status_updated_at).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}</h6>
            }
            {isChanged &&
              <span
                style={{ cursor: "pointer" }}
                onClick={handleSubmit}
              >
                save
              </span>
            }
          </div>
          <div className="field-box flex-box align-center space-between" style={{ marginTop: "1rem" }}>
            <h6>Payment Date & Time</h6>
            <h4>
              {new Date(data1?.createdAt).toLocaleString("en-US", {
                day: "numeric",
                month: "long",
                weekday: "long",
              })}
            </h4>
          </div>
          <div className="field-box flex-box align-center space-between">
            <h6>Payment Status</h6>
            <h4>{'Paid'}</h4>
          </div>
          <div className="text-head-box flex-box align-center space-between">
            <h6>Item Order Amount</h6>
            <h4>${data?.price}</h4>
          </div>
        </li>

        {data1?.collection_details !== null && (
          <>
            <li className="item-detail-inner-box">
              <h6 className="tab-detail-heading">
                {" "}
                <span>
                  <img src={collectionDetailIcon} alt="" />
                </span>{" "}
                Collection Details
              </h6>
              <div className="field-box">
                <h6>Collected On</h6>
                <h4>3rd April, Wednesday</h4>
              </div>
              <div className="field-box">
                <h6>Collected By</h6>
                <h4>Adam Jones</h4>
              </div>
              <div className="field-box">
                <h6>Image At Collection</h6>
                <img src={imageAtCollection} alt="" />
              </div>
              <div className="field-box">
                <h6>Collection Mode </h6>
                <h4>Home Pick-up</h4>
                <h6 className="address">
                  601 Roy St, Seattle, WA 98109, United States
                </h6>
              </div>
            </li>
          </>
        )}
      </ul>
    </li>
  );
}

export default ItemDetail;
