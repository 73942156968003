import axios from "axios"
const pathURL = process.env.REACT_APP_API_URL;

export async function fetchFixer(data) {
    try {
        return await axios.get(
            `${pathURL}fixer/getallfixer`,
        );
    } catch (error) {
        return error.response;
    }
}

export async function fetchSingleFixer(id) {
    try {
        return await axios.get(
            `${pathURL}fixer/getonefixer/${id}`,
        );
    } catch (error) {
        return error.response;
    }
}

export async function addFixer(data) {
    try {
        return await axios.post(`${pathURL}fixer/createfixer`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        return error.response;
    }
}

export async function updateFixer(id, data) {
    try {
        return await axios.patch(
            `${pathURL}fixer/updatefixer/${id}`,
            data,
        );
    } catch (error) {
        return error.response;
    }
}

export async function deleteFixer(id) {
    try {
        return await axios.delete(
            `${pathURL}fixer/deletefixer/${id}`
        );
    } catch (error) {
        return error.response;
    }
}