import React, { useEffect, useState } from "react";
import discountsIcon from "../../assets/img/menu-icon-7.webp";
import Loader from "../common/Loader";
import toast from "react-hot-toast";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
function ItemTab({ title, value }) {
  return (
    <div className="order-detail-box">
      <h6>{title}</h6>
      <h4>{value || "-"}</h4>
    </div>
  );
}

function AllSubscribersTab() {
  const fetchAllCustomers = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}users/getallsubscribers`
    );
    return res.data;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchAllCustomers"],
    queryFn: fetchAllCustomers,
  });
  return (
    <div className="tab-detail-outer-box">
      <div className="head-box flex-box space-between align-center">
        <h6 className="tab-detail-heading">
          {" "}
          <span>
            <img src={discountsIcon} alt="" />
          </span>{" "}
          All Subscribers
        </h6>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="all-orders-listing all-subscribers-listing">
          {data?.map((customers, index) => (
            <li key={index}>
              <Link key={index} className="order-box flex-box align-center space-between">
                <ItemTab title="Name" value={customers?.name} />
                <ItemTab title="Subscribed" value={"Yes"} />
                <ItemTab title="Email" value={customers?.email} />
                <ItemTab title="Phone" value={customers?.phone || "NA"} />
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default AllSubscribersTab;
