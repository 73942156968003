import React, { useState } from "react";
import { Link } from "react-router-dom";
import ctaArrow from "../../assets/img/cta-arrow.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import axios from "axios";
import toast from "react-hot-toast";

function ItemTab({ title, value, status }) {
  let statusIcon = null;

  if (status === "Dispute") {
    statusIcon = <span className="status-dot orange"></span>;
  } else if (status === "Completed") {
    statusIcon = <span className="status-dot green"></span>;
  } else if (status === "Pending") {
    statusIcon = <span className="status-dot red"></span>;
  }

  return (
    <div className="order-detail-box">
      <h6>{title}</h6>
      <h4>
        {value}
        {statusIcon}
      </h4>
    </div>
  );
}
const calculatePickupDate = (deliveryDate) => {
  const deliveryDay = new Date(deliveryDate).getDay(); // 0 represents Sunday, 1 represents Monday, 3 represents Wednesday
  const pickupOffset = deliveryDay === 1 ? 10 : deliveryDay === 3 ? 12 : 0; // Offset based on delivery day
  const pickupDate = new Date(deliveryDate);
  pickupDate.setDate(pickupDate.getDate() + pickupOffset); // Add offset to delivery date
  return pickupDate.toISOString(); // Convert to ISO format for consistency
};
function PaidPayouts({ paidPayouts, data }) {

  const [showMainPopup, setShowMainPopup] = useState(false);

  const openMainPopup = () => {
    setShowMainPopup(true);
  };
  const closeMainPopup = () => {
    setShowMainPopup(false);
  };

  const handleChangeStatus = async (value,id) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}order/markedaspaid/${id}`, {
      status : value
    })
    if(response.status == 200){
      toast.success("Status Updated Successfully!")
      closeMainPopup()
    }
  }

  return (
    <li>
      <Link
        onClick={openMainPopup}
        className="order-box flex-box align-center space-between"
      >
        <ItemTab title="Fixer Name" value={paidPayouts?.fixer_details?.name} />
        <ItemTab title="Order Id /SKU" value={paidPayouts?.order_id_custom} />
        <ItemTab
          title="Order Date"
          value={new Date(paidPayouts?.createdAt).toLocaleDateString()}
        />
        {/* <ItemTab title="Completed On" value={""} /> */}
        <ItemTab
          title="Status"
          value={paidPayouts?.status}
          status={paidPayouts?.status}
        />
        <div className="order-cta-box">
          <img src={ctaArrow} alt="" />
        </div>
      </Link>
      {showMainPopup && (
        <div className="popup-outer-box">
          <div className="popup-box user-edit-popup item-popup fixer-payouts-popup">
            <button className="popup-close-btn" onClick={closeMainPopup}>
              <img src={popupCloseIcon} alt="" />
            </button>
            <div className="popup-edit-box flex-box space-between">
              <div className="img-change-box relative">
                <img
                  className="user-edit-img"
                  src={paidPayouts?.fixer_details?.image}
                  alt=""
                />
              </div>
              <div className="user-text-box">
                <div className="field-box">
                  <h6>Tailor Name</h6>
                  <h4 className="edit-name">
                    {paidPayouts?.fixer_details?.name}
                  </h4>
                </div>
                <div className="field-box">
                  <h6>Contact Info</h6>
                  <h4 className="mb">{paidPayouts?.fixer_details?.email}</h4>
                  <h4 className="edit-none">
                    {paidPayouts?.fixer_details?.phone}
                  </h4>
                </div>
              </div>
              <div className="tailor-payout-box">
                <h6>Tailor Payout</h6>
                <h3>$ {paidPayouts?.price}</h3>
                <h4>MARK AS</h4>
                <select onChange={(e) => handleChangeStatus(e.target.value,paidPayouts?._id)}>
                  <option value="Paid" selected={paidPayouts?.is_paid == "Paid"}>Paid</option>
                  <option value="Pending" selected={paidPayouts?.is_paid == "Pending"}>Pending</option>
                  <option value="Dispute" selected={paidPayouts?.is_paid == "Dispute"}>Dispute</option>
                  <option value="Cancelled" selected={paidPayouts?.is_paid == "Cancelled"}>Cancelled</option>
                </select>
              </div>
            </div>
            <div className="popup-down-box flex-box space-between">
              <div className="field-box">
                <h6>Order Id/SKU</h6>
                <h4>{paidPayouts?.order_id_custom}</h4>
              </div>
              <div className="field-box">
                <h6>Order Date</h6>
                <h4>{new Date(paidPayouts?.createdAt).toLocaleDateString()}</h4>
              </div>
              {/* <div className="field-box">
                <h6>Completed On</h6>
                <h4>{completedOn}</h4>
              </div> */}
            </div>
            <div className="fixer-payouts-listing">
              <div className="title-box flex-box space-between">
                <div className="field-box">
                  <h6>Item</h6>
                </div>
                <div className="field-box">
                  <h6>Category</h6>
                </div>
                <div className="field-box">
                  <h6>Services</h6>
                </div>
                <div className="field-box">
                  <h6>Amount</h6>
                </div>
              </div>
              <div
                className="list-box flex-box space-between"
                key={paidPayouts?._id}
              >
                <div className="field-box">
                  <h4>{paidPayouts?.service_details?.name}</h4>
                </div>
                <div className="field-box">
                  <h4>{paidPayouts?.service_details?.selectedService}</h4>
                </div>
                <div className="field-box">
                  <h4>{paidPayouts?.service_details?.selectedOption}</h4>
                </div>
                <div className="field-box">
                  <h4>{paidPayouts?.price}</h4>
                </div>
              </div>
            </div>
            <div className="bottom-save-box flex-box space-between align-center">
              <button className="save-info-btn">Save</button>
            </div>
          </div>
        </div>
      )}
    </li>
  );
}

export default PaidPayouts;
