import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import popupCloseIcon from '../../assets/img/popup-close-icon.webp';
import crossIcon from '../../assets/img/cross-icon.webp';
import deleteIcon from '../../assets/img/only-delete.webp';
import axios from 'axios';
import toast from 'react-hot-toast';

function ItemTab({ value, deleteClick }) {

    return (
        <div className="order-detail-box flex-box space-between">
            <h4>{value}</h4>
            <span onClick={deleteClick} >  <img src={crossIcon} alt="" /> </span>
        </div>
    );
}

function Zipcode({ zipcodes, refetch }) {
    const { zipcode } = zipcodes;

    const [showMainPopup, setShowMainPopup] = useState(false);
    const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
    const [isWarningBoxVisible, setIsWarningBoxVisible] = useState(false);
    const [zipDetail, setzipDetail] = useState(null)
    const openMainPopup = async (code) => {
        setShowMainPopup(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}zipcodes/getone/${code}`);
        if (response.status === 200) {
            setzipDetail(response.data)
        }
    };
    const handleDeleteClick = () => {
        setIsConfirmBoxVisible(true);
    };
    const closeMainPopup = () => {
        setShowMainPopup(false);
        setIsConfirmBoxVisible(false);
        setIsWarningBoxVisible()
    };
    const handleWarningBox = async (zipcode) => {
        // setIsWarningBoxVisible(true)
        let resp = await axios.delete(`${process.env.REACT_APP_API_URL}zipcodes/delete/${zipcode}`);
        if(resp.status == 200){
            toast.success("Deleted Successfully!")
            closeMainPopup()
        }
        refetch()
    };


    return (
        <li>
            <Link
                onClick={() => openMainPopup(zipcode)}
                className='order-box flex-box align-center space-between'>
                <ItemTab value={zipcode} deleteClick={() => handleDeleteClick(zipcode)} />
            </Link>
            {showMainPopup && zipDetail != null && (
                <div className="popup-outer-box">
                    <div className={`popup-box user-edit-popup item-popup zip-code-popup ${isConfirmBoxVisible ? 'confirm-box' : ''}`}>
                        <button className='popup-close-btn' onClick={closeMainPopup}><img src={popupCloseIcon} alt="" /></button>
                        <div className="popup-edit-box flex-box space-between">
                            <div className="text-box">
                                <div className="field-box">
                                    <h6>City Name</h6>
                                    <h4>{zipDetail?.cityname}</h4>
                                </div>
                                <div className="field-box">
                                    <h6>Zip Code</h6>
                                    <h4>{zipcode}</h4>
                                </div>
                                <div className="field-box no-border">
                                    <h6>Street Address</h6>
                                    <h4>{zipDetail?.address?.line1}, {zipDetail?.address?.line2}, {zipDetail?.address?.state}</h4>
                                </div>
                                <div className="bottom-save-box flex-box space-between align-center">
                                    <button className='save-info-btn bottom-del-btn' onClick={handleDeleteClick} >Delete <span><img src={deleteIcon} alt="" /></span></button>
                                </div>
                            </div>
                            <div className="map-box">
                                <iframe
                                    title="Downtown Seattle Map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21520.537162113087!2d-122.33553720000003!3d47.60538405000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906ab6b122572d%3A0x4cc65f51348e1d43!2sDowntown%20Seattle%2C%20Seattle%2C%20WA%2C%20USA!5e0!3m2!1sen!2sin!4v1716205182380!5m2!1sen!2sin"
                                    width="250"
                                    height="320"
                                    style={{ borderRadius: "0.5rem", border: "none" }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                            <div className="confirm-delete-box" style={{ display: "none" }} >
                                <h4>Are you sure you want to delete?</h4>
                                <h6>This change can not be undone.</h6>
                                <div className="btn-outer-box">
                                    <button onClick={() => handleWarningBox(zipcode)} className='save-info-btn confirm-del-btn' >Yes, delete It</button>
                                    <button onClick={closeMainPopup} className='save-info-btn cancel-btn' >Cancel</button>
                                    <div style={{ display: "none" }} className={`can-not-del-box  ${isWarningBoxVisible ? 'warning' : ''}`}>
                                        <h4>Hey, you can not do this stage because  there is an order placed on this parameter</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </li>
    );
}

export default Zipcode