import React, { useState } from 'react'
import zipcodeIcon from '../../assets/img/menu-icon-8.webp';
import Zipcode from '../module-tabs-detail/Zipcode';
import popupCloseIcon from '../../assets/img/popup-close-icon.webp';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useQuery } from 'react-query';

function AllZipcodesTab() {
   const [showAddPopup, setShowAddPopup] = useState(false);
   const [addZipCode, setAddZipCode] = useState('');
   const [addCityName, setAddCityName] = useState('');
   const [addStreet, setAddStreet] = useState('');
   const [addLaneOne, setAddLaneOne] = useState('');
   const [addLaneTwo, setAddLaneTwo] = useState('');


   const fetchZipCodes = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}zipcodes/all`)
      return response.data;
   }

   const {
      data, isLoading, refetch
   } = useQuery("fetchZipCodes", fetchZipCodes)


   const openAddPopup = () => {
      setShowAddPopup(true);
   };
   const closeAddPopup = () => {
      setShowAddPopup(false);
   };
   const handleAddZipCode = (e) => {
      setAddZipCode(e.target.value);
   };
   const handleAddCity = (e) => {
      setAddCityName(e.target.value);
   };
   const handleAddStreet = (e) => {
      setAddStreet(e.target.value);
   };
   const handleAddLane1 = (e) => {
      setAddLaneOne(e.target.value);
   };
   const handleAddLane2 = (e) => {
      setAddLaneTwo(e.target.value);
   };

   const handleSubmit = async () => {
      const data = {
         zipcode: addZipCode,
         cityname: addCityName,
         address: {
            state: addStreet,
            line1: addLaneOne,
            line2: addLaneTwo
         }
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}zipcodes/create`, data);
      if (response.status == 200) {
         console.log("Added Successfully");
         toast('Added Successfully!')
         refetch()
         setShowAddPopup(false)
      }
   }

   return (
      <div className="tab-detail-outer-box zipcode-listing-box">
         <div className="tab-detail-head-box flex-box space-between">
            <h6 className='tab-detail-heading'> <span><img src={zipcodeIcon} alt="" /></span> Active Zipcodes </h6>
            <div className="right-box flex-box align-center">
               <h4 className='export-csv-btn' >Import CSV</h4>
               <h4 className='export-csv-btn add-zip-code' onClick={openAddPopup} >Add Zip code</h4>
               {showAddPopup && (
                  <div className="popup-outer-box">
                     <div className={`popup-box user-edit-popup item-popup zip-code-popup add-zip-code`}>
                        <button className='popup-close-btn' onClick={closeAddPopup}><img src={popupCloseIcon} alt="" /></button>
                        <div className="popup-edit-box flex-box space-between">
                           <div className="text-box">
                              <div className="flex-box space-between ">
                                 <div className="input-field-box">
                                    <h6>Zip Code</h6>
                                    <input className='popup-input' value={addZipCode} onChange={handleAddZipCode} type="number" placeholder="98039" />
                                 </div>
                                 <div className="input-field-box">
                                    <h6>City Name</h6>
                                    <input className='popup-input' value={addCityName} onChange={handleAddCity} type="text" placeholder="Seattle" />
                                 </div>
                              </div>
                              <div className="input-field-box">
                                 <h6>Address</h6>
                                 <input className='popup-input' value={addStreet} onChange={handleAddStreet} type="text" placeholder="Street" />
                                 <input className='popup-input' value={addLaneOne} onChange={handleAddLane1} type="text" placeholder="1st Line" />
                                 <input className='popup-input' value={addLaneTwo} onChange={handleAddLane2} type="text" placeholder="2nd Line" />
                              </div>
                              <div className="bottom-save-box flex-box space-between align-center">
                                 <button type='button' onClick={handleSubmit} className='save-info-btn' >Save</button>
                              </div>
                           </div>
                           <div className="map-box">
                              <iframe
                                 title="Downtown Seattle Map"
                                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21520.537162113087!2d-122.33553720000003!3d47.60538405000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906ab6b122572d%3A0x4cc65f51348e1d43!2sDowntown%20Seattle%2C%20Seattle%2C%20WA%2C%20USA!5e0!3m2!1sen!2sin!4v1716205182380!5m2!1sen!2sin"
                                 width="200"
                                 height="320"
                                 style={{ borderRadius: "0.5rem", border: "none" }}
                                 allowFullScreen=""
                                 loading="lazy"
                                 referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
         <ul className='all-orders-listing'>
            <h6 className='active-tag' >ACTIVE</h6>
            {isLoading ? (
               <>
                  <div className="flex items-center justify-center h-32">
                     <div className="flex items-center justify-center space-x-2">
                        <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"></div>
                        <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"></div>
                        <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"></div>
                     </div>
                  </div>
               </>
            ) : (
               <>
                  {!isLoading && data
                     .map((zipcodes, index) => (
                        <Zipcode key={index} zipcodes={zipcodes} refetch={refetch} />
                     ))}
               </>
            )}

         </ul>
      </div>
   );
}

export default AllZipcodesTab;