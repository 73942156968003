import React, { useEffect, useState } from 'react'
import crossIcon from '../assets/img/empty-input.webp'
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function LogIn() {
    const [adminUserName, setAdminUserName] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const navigate = useNavigate()
    const handleAdminUserName = (e) => {
        setAdminUserName(e.target.value)
    }
    const handleAdminPassword = (e) => {
        setAdminPassword(e.target.value)
    }
    const clearAdminUserName = () => {
        setAdminUserName('');
    };

    const handleSubmit = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}users/loginsuperadmin`, {
            email: adminUserName,
            password: adminPassword
        });
        if (response.status === 200) {
            toast.success("Logged In Successfully!")
            localStorage.setItem("fxryToken", response.data.token)
            navigate("/dashboard")
        } else {
            toast.error(response.data.message)
        }
    }
    useEffect(() => {
        const token = localStorage.getItem("fxryToken")
        if (token) {
            navigate("/dashboard")
        }

    }, [])

    return (
        <section className='login-section' >
            <div className="container">
                <div className="login-main-box flex-box space-between align-center">
                    <div className="login-left-box">
                        <div className="text-box">
                            <h2>FXRY Login</h2>
                            <p>Elevate your style with FXRY Clothing's expert alteration services. From precise hemming to custom fits, we craft perfection for a refined.</p>
                        </div>
                    </div>
                    <div className="login-right-box">
                        <h1>Fxry Admin Dashboard</h1>
                        <h6>Hello, Nice to see you</h6>
                        <div className="form-box">
                            <div className="feild-box flex-box">
                                <h4>User Name</h4>
                                <div className="relative cross-box">
                                    <input className='login-input' value={adminUserName} onChange={handleAdminUserName} type="text" placeholder="Jasith Smith" />
                                    {adminUserName && (
                                        <img className="clear-icon" onClick={clearAdminUserName} src={crossIcon} alt="" />
                                    )}
                                </div>
                                <h4>Password</h4>
                                <input className='login-input' value={adminPassword} onChange={handleAdminPassword} type="password" placeholder="Password" />
                            </div>
                            <button onClick={handleSubmit} className='save-info-btn'>Log In</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LogIn