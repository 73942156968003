import { Constant } from "../Constant";

export const addDiscountAction = (value) => {
    return {
        type: Constant.addDiscount,
        payload: value,
    };
};

export const updateDiscountAction = (id, data) => {
    return {
        type: Constant.updateDiscount,
        payload: { id, data },
    };
};

export const deleteDiscountAction = (data) => {
    return {
        type: Constant.deleteDiscount,
        payload: data,
    };
};

export const fetchDiscountAction = (data) => {
    return {
        type: Constant.fetchDiscount,
        payload: data,
    };
};

export const fetchSingleDiscountAction = (data) => {
    return {
        type: Constant.setDiscount,
        payload: data,
    };
};
