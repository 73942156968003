import { put, takeLatest, call, all } from "redux-saga/effects";
import { Constant } from "../Constant";
import { addFixer, deleteFixer, fetchFixer, updateFixer } from "../../server/fixer";

function* fetchFixerData() {
    try {
        const response = yield call(fetchFixer);
        yield put({ type: Constant.setFixer, payload: response?.data || [] });
    } catch (error) {
        return console.log(error);
    }
}


function* addFixerData(action) {
    try {
        yield call(addFixer, action.payload);
        yield call(fetchFixerData);
    } catch (error) {
        return console.log(error);
    }
}

function* updateFixerData(action) {
    let { id, data } = action.payload;
    console.log(id, data);
    try {
        yield call(updateFixer, id, data);
        yield call(fetchFixerData);
    } catch (error) {
        console.log(error);
    }
}

function* deleteFixerData(action) {
    try {
        yield call(deleteFixer, action.payload);
        yield call(fetchFixerData);
    } catch (error) {
        console.log(error);
    }
}

export function* watchFixerActions() {
    yield all([
        takeLatest(Constant.fetchFixer, fetchFixerData), takeLatest(Constant.addFixer, addFixerData), takeLatest(Constant.updateFixer, updateFixerData), takeLatest(Constant.deleteFixer, deleteFixerData),])

}