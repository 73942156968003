import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, combineReducers } from "redux";
import rootSaga from "../Sagas/rootSaga";
import { serviceReducer } from "../Reducer/serviceReducer";
import { itemReducer } from "../Reducer/itemReducer";
import { fixerReducer } from "../Reducer/fixerReducer";
import { discountReducer } from "../Reducer/discountReducer";
const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  serviceReducer,
  itemReducer,
  fixerReducer,
  discountReducer
});

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

export default store;
