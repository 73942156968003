import { Constant } from "../Constant";

export const addServiceAction = (value) => {
    return {
        type: Constant.addService,
        payload: value,
    };
};

export const fetchServiceAction = (data) => {
    return {
        type: Constant.fetchService,
        payload: data,
    };
};

export const fetchSingleServiceAction = (data) => {
    return {
        type: Constant.fetchSingleService,
        payload: data,
    };
};

export const deleteServiceAction = (data) => {
    return {
        type: Constant.deleteService,
        payload: data,
    };
};

export const updateServiceAction = (id, data) => {
    return {
        type: Constant.updateService,
        payload: { id, data },
    };
};
