import React from 'react'

function CustomerDetailBox({ customerName, totalOrder, totalAmountSpend, customer }) {

  return (
    <div className="user-detail-box customer-detail-box flex-box space-between" >
            <ul className='user-detail-listing flex-box space-between'>
                <li>
                    <div className="text-box">
                        <h6>Customer Name</h6>
                        <h4>{customerName}</h4>
                    </div>
                    <div className="text-box">
                        <h6>Lifetime Orders</h6>
                        <h4>{totalOrder}</h4>
                    </div>
                </li>
                <li>
                    <div className="text-box">
                        <h6>Mobile</h6>
                        <h4>{customer?.phone}</h4>
                    </div>
                    <div className="text-box">
                        <h6>Subscribed</h6>
                        <h4>{customer?.is_subscribed == false ? 'Un-subscribed' : 'Subscribed' }</h4>
                    </div>
                </li>
                <li>
                    <div className="text-box">
                        <h6>Email Address</h6>
                        <h4>{customer?.email}</h4>
                    </div>
                    {/* <div className="text-box">
                        <h6>Mobile</h6>
                        <h4>{customer?.phone}</h4>
                    </div> */}
                </li>
            </ul>
            <div className="total-amount-spend-box">
                <h6>Total Amount <br/>Spent</h6>
                <h4>$ {totalAmountSpend}</h4>
            </div>
        </div>
  )
}

export default CustomerDetailBox