import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ordersIcon from "../../assets/img/menu-icon-1.webp";
import customersIcon from "../../assets/img/menu-icon-2.webp";
import itemsIcon from "../../assets/img/menu-icon-3.webp";
import servicesIcon from "../../assets/img/menu-icon-4.webp";
import fixersIcon from "../../assets/img/menu-icon-5.webp";
import fixerPayoutsIcon from "../../assets/img/menu-icon-6.webp";
import discountsIcon from "../../assets/img/menu-icon-7.webp";
import zipcodesIcon from "../../assets/img/menu-icon-8.webp";
import hamburger from "../../assets/img/hamburger.png";
import closeMenu from "../../assets/img/close-mobile-menu.png";

function MenuBox (props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedInnerTab, setSelectedInnerTab] = useState(); 
  const [selectedInnerTab2, setSelectedInnerTab2] = useState();
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    navigate(`/${tab}`, { state: {} });
    setIsActive(false);
  };

  const handleInnerTabClick = (tab) => {
    setSelectedInnerTab(tab);
    navigate(`/${tab}`, { state: {} });
    setIsActive(false);
  };

  const handleInnerTabClick2 = (tab) => {
    setSelectedInnerTab2(tab);
    navigate(`/${tab}`, { state: {} });
    setIsActive(false);
  };

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const tab = pathSegments[1];
    
    if (tab && (tab === "intake" || tab == "readyfordelivery" || tab === "picked" || tab === "withfixer" || tab === "fixedbutwithfixer" || tab === "fixedandwithfxry" || tab === "delivered" || tab === "onhold" || tab === "archived")) {
      setSelectedInnerTab(tab);
      setSelectedTab(tab);
    }

    if (tab && (tab === "paid" || tab === "pending" || tab === "dispute")) {
      setSelectedInnerTab2(tab);
    }

    if (["customers", "items", "services", "fixers", "discounts", "payments", "zipcodes", "orders", "subscribers", "overview"].includes(tab)) {
      setSelectedTab(tab);
    }

    if (props.selectedInnerTab) {
      setIsMenuOpen(true);
    }
  }, [location.pathname, props.selectedInnerTab]);

  return (
    <div className="main-menu-box">
      <h6 className="menu-sub-text mobile-none">quick links</h6>
      <div className="mobile-menu desktop-none">
        <img 
          className="hamburger-icon" 
          src={hamburger} 
          alt="Hamburger Menu Icon" 
          onClick={toggleMenu}
        />
      </div>
      <ul className={`menu-listing ${isActive ? 'active' : ''}`}>
        <img
          className="close-mobile-menu desktop-none"
          src={closeMenu} 
          alt="Close Menu Icon" 
          onClick={toggleMenu} 
        />
        <h6 className="menu-sub-text desktop-none">quick links</h6>
        <li className="order-main-tab">
          <div
            className={`tab-box ${selectedTab === "dashboard" ? "selected" : ""}`}
            onClick={() => {
              handleMenuOpen();
              handleInnerTabClick("dashboard");
            }}
          >
            <h6>
              <span>
                <img src={ordersIcon} alt="" />
              </span>
              Orders
            </h6>
          </div>
          <div className={`menu-inner-listing ${isMenuOpen ? "full-height" : ""}`}>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab === "intake" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick("intake")}
            >
              <h6>Intake</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab === "picked" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick("picked")}
            >
              <h6>Items Picked</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab === "withfixer" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick("withfixer")}
            >
              <h6>Items With Fixer</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab === "fixedbutwithfixer" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick("fixedbutwithfixer")}
            >
              <h6>Fixed with Fixer</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab === "fixedandwithfxry" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick("fixedandwithfxry")}
            >
              <h6>Fixed with FXRY</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab === "readyfordelivery" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick("readyfordelivery")}
            >
              <h6>Ready For Delivery</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab === "delivered" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick("delivered")}
            >
              <h6>Delivered</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab === "archived" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick("archived")}
            >
              <h6>Archived</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab === "onhold" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick("onhold")}
            >
              <h6>On-Hold</h6>
            </div>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${selectedTab === "customers" ? "selected" : ""}`}
            onClick={() => handleTabClick("customers")}
          >
            <h6>
              <span>
                <img src={customersIcon} alt="" />
              </span>
              Customers
            </h6>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${selectedTab === "items" ? "selected" : ""}`}
            onClick={() => handleTabClick("items")}
          >
            <h6>
              <span>
                <img src={itemsIcon} alt="" />
              </span>
              Items
            </h6>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${selectedTab === "services" ? "selected" : ""}`}
            onClick={() => handleTabClick("services")}
          >
            <h6>
              <span>
                <img src={servicesIcon} alt="" />
              </span>
              Services
            </h6>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${selectedTab === "fixers" ? "selected" : ""}`}
            onClick={() => handleTabClick("fixers")}
          >
            <h6>
              <span>
                <img src={fixersIcon} alt="" />
              </span>
              Fixers
            </h6>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${
              selectedTab === "fixer-payouts" ? "selected" : ""
            }`}
            // onClick={() => handleTabClick("fixer-payouts")}
          >
            <h6>
              <span>
                <img src={fixerPayoutsIcon} alt="" />
              </span>
              Fixer Payouts
            </h6>
          </div>
          <div className={`menu-inner-listing ${isMenuOpen ? "full-height" : ""}`}>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab2 === "paid" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick2("paid")}
            >
              <h6>Paid</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab2 === "pending" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick2("pending")}
            >
              <h6>Pending</h6>
            </div>
            <div
              className={`menu-inner-tab-box ${selectedInnerTab2 === "dispute" ? "selected" : ""}`}
              onClick={() => handleInnerTabClick2("dispute")}
            >
              <h6>Dispute</h6>
            </div>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${selectedTab === "discounts" ? "selected" : ""}`}
            onClick={() => handleTabClick("discounts")}
          >
            <h6>
              <span>
                <img src={discountsIcon} alt="" />
              </span>
              Discounts
            </h6>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${selectedTab === "zipcodes" ? "selected" : ""}`}
            onClick={() => handleTabClick("zipcodes")}
          >
            <h6>
              <span>
                <img src={zipcodesIcon} alt="" />
              </span>
              Zipcodes
            </h6>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${selectedTab === "subscribers" ? "selected" : ""}`}
            onClick={() => handleTabClick("subscribers")}
          >
            <h6>
              <span>
                <img src={zipcodesIcon} alt="" />
              </span>
              Subscribers
            </h6>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${selectedTab === "overview" ? "selected" : ""}`}
            onClick={() => handleTabClick("overview")}
          >
            <h6>
              <span>
                <img src={ordersIcon} alt="" />
              </span>
              Orders Overview
            </h6>
          </div>
        </li>
        <li>
          <div
            className={`tab-box ${selectedTab === "hubspotsoverview" ? "selected" : ""}`}
            onClick={() => handleTabClick("hubspotsoverview")}
          >
            <h6>
              <span>
                <img src={ordersIcon} alt="" />
              </span>
              HubSpots Overview
            </h6>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default MenuBox;