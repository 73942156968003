import React, { useEffect } from 'react';
import MenuBox from '../components/common/MenuBox';
import AllOrderTab from '../components/module-tabs/AllOrderTab';
import { useNavigate } from 'react-router-dom';

function DashBoard() {
  const navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem("fxryToken")
    if (!token) {
      navigate("/")
    }
  }, [])
  return (
    <section className='main-dashboard-section' >
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox />
          <AllOrderTab />
        </div>
      </div>
    </section>
  )
}

export default DashBoard