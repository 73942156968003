import React from 'react';
import { useLocation } from 'react-router-dom';
import MenuBox from '../components/common/MenuBox';
import AllItemsTab from '../components/module-tabs/AllItemsTab';
import AllOrderTab from '../components/module-tabs/AllOrderTab';
import AllServicesTab from '../components/module-tabs/AllServicesTab';
import AllCustomersTab from '../components/module-tabs/AllCustomersTab';
import AllFixersTab from '../components/module-tabs/AllFixersTab';
import AllDiscountsTab from '../components/module-tabs/AllDiscountsTab';
import AllPaidPayoutsTab from '../components/module-tabs/AllPaidPayoutsTab';
import AllZipcodesTab from '../components/module-tabs/AllZipcodesTab';
import AllSubscribersTab from '../components/module-tabs/AllSubscribers';
import AllOverviewTab from '../components/module-tabs/AllOverviewTab';
import AllHubSpotsTab from '../components/module-tabs/AllHubSpotsTab';

function CommonPage() {
  const location = useLocation();
  const pathSegments = location.pathname.substring(1);

  return (
    <section className='main-dashboard-section'>
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox />
          {pathSegments === 'intake' || pathSegments === 'picked' || pathSegments === 'withfixer' || pathSegments === 'delivered' || pathSegments === 'fixedandwithfxry' || pathSegments === 'fixedbutwithfixer' || pathSegments === 'archived' || pathSegments === 'onhold' ? (
            <AllOrderTab />
          ) : pathSegments === 'items' ? (
            <AllItemsTab />
          ) : pathSegments === 'services' ? (
            <AllServicesTab />
          ) : pathSegments === 'customers' ? (
            <AllCustomersTab />
          ) : pathSegments === 'fixers' ? (
            <AllFixersTab />
          ) : pathSegments === 'discounts' ? (
            <AllDiscountsTab />
          ) : pathSegments === 'paid' || pathSegments === 'pending' || pathSegments === 'dispute' ? (
            <AllPaidPayoutsTab />
          ) : pathSegments === 'zipcodes' ? (
            <AllZipcodesTab />
          ) : pathSegments === 'subscribers' ? (
            <AllSubscribersTab />
          ) : pathSegments === 'overview' ? (
            <AllOverviewTab />
          ): pathSegments === 'hubspotsoverview' ? 
            <AllHubSpotsTab />
          : (
            <h1>Module Not Added</h1>
          )}
        </div>
      </div>
    </section>
  );
}

export default CommonPage;
