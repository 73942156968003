import { Constant } from "../Constant";

const initialState = {
    allItem: [],
    singleItem: ""
};

export const itemReducer = (state = initialState, action) => {
    switch (action.type) {
        case Constant.setItem:
            return { ...state, allItem: action.payload };
        case Constant.setSingleService:
            return { ...state, singleItem: action.payload }
        default:
            return state;
    }
};
