import React, { useState } from 'react'
import userIcon from '../../assets/img/user-icon.webp';
import infoIcon from '../../assets/img/info-icon.webp'

function UserDetailBox({ name, itemCount, items, order }) {


    const [isBoxVisible, setIsBoxVisible] = useState(false)

    const toggleBoxVisibility = () => {
        setIsBoxVisible(!isBoxVisible)
    }

    console.log(order);

    return (
        <div className="user-detail-box">
            <div className="head-box flex-box space-between align-center">
                <h6 className='tab-detail-heading f-20'> <span><img src={userIcon} alt="" /></span> User Details</h6>
                <h6 className='user-order-id'>Oder ID: <strong>#{order._id}</strong> </h6>
            </div>
            <ul className='user-detail-listing flex-box space-between'>
                <li>
                    <div className="text-box">
                        <h6>Name</h6>
                        <h4>{name}</h4>
                    </div>
                    <div className="text-box">
                        <h6>Items</h6>
                        <h4>{order?.order_items?.map((it) => ( <>{it?.type}</> ))}</h4>
                    </div>
                </li>
                <li>
                    <div className="text-box">
                        <h6>Item Count</h6>
                        <h4>{itemCount}</h4>
                    </div>
                    <div className="text-box">
                        <h6>Email</h6>
                        <h4>{order?.item?.customer_id?.email}</h4>
                    </div>
                </li>
                <li>
                    <div className="text-box">
                        <h6>Pick-up Date</h6>
                        <h4>30th March, Wed</h4>
                    </div>
                    <div className="text-box">
                        <h6>Mobile</h6>
                        <h4>{order?.item?.customer_id?.number}</h4>
                    </div>
                </li>
                <li>
                    <div className="text-box relative">
                        <h6>Total Order Amount</h6>
                        <h4 id='price-info' onClick={toggleBoxVisibility} >$400.00 <span><img src={infoIcon} alt="" /></span></h4>
                        <div className={`price-info-box ${isBoxVisible ? 'show' : ''}`}>
                            <div className="text-box">
                                <h6>Order Amount</h6><h4>-</h4><h6>$150.00</h6>
                            </div>
                            <div className="text-box">
                                <h6>15 % inclusive tax</h6><h4>-</h4><h6>$60.00</h6>
                            </div>
                            <div className="text-box">
                                <h6>Platform Fee</h6><h4>-</h4><h6>$20.00</h6>
                            </div>
                            <div className="text-box">
                                <h6>Applied Discount</h6><h4>-</h4><h6>$0.00</h6>
                            </div>
                            <div className="text-box">
                                <h6>Service Based Price</h6><h4>-</h4><h6>$70.00</h6>
                            </div>
                        </div>
                    </div>
                    <div className="text-box">
                        <h6>Address</h6>
                        <h4>601 Roy St, Seattle, WA 98109, United States</h4>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default UserDetailBox