import axios from "axios"
const pathURL = process.env.REACT_APP_API_URL;

export async function fetchItem(data) {
    try {
        return await axios.get(
            `${pathURL}item/getallitem`,
        );
    } catch (error) {
        return error.response;
    }
}

export async function fetchSingleItem(id) {
    try {
        return await axios.get(
            `${pathURL}item/getoneitem/${id}`,
        );
    } catch (error) {
        return error.response;
    }
}

export async function addItem(data) {
    try {
        return await axios.post(`${pathURL}item/createitem`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        return error.response;
    }
}

export async function updateItem(id, data) {
    try {
        return await axios.patch(
            `${pathURL}item/updateitem/${id}`,
            data,
        );
    } catch (error) {
        return error.response;
    }
}

export async function deleteItem(id) {
    try {
        return await axios.delete(
            `${pathURL}item/deleteitem/${id}`
        );
    } catch (error) {
        return error.response;
    }
}