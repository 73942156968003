import React, { useEffect, useState } from "react";
import ordersIcon from "../../assets/img/menu-icon-1.webp";
import axios from "axios"
function AllOverviewTab() {

  const [loading, setLoading] = useState(true)
  const [selectedOverviewTab, setSelectedOverviewTab] = useState("Intake");
  const [activeDays, setActiveDays] = useState(7);  // Corrected typo
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredOrdersData, setFilteredOrdersData] = useState([]);
  const [fixers,setFixers] = useState([])
  const [selectedFixer,setSelectedFixer] = useState(null)

  console.log(selectedFixer)

  const handleTabClick = (tab) => {
    setSelectedOverviewTab(tab);
  };

  const fetchOrdersOnDaysBasis = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}order/allordersdateandfixerbasis`);
    setOrders(response?.data);
    if (activeDays === 7) {
      setFilteredOrders(response?.data[0]?.last7Days?.reverse());
      setLoading(false)
    } else if (activeDays === 30) {
      setFilteredOrders(response?.data[0]?.last30Days?.reverse());
      setLoading(false)
    } else if (activeDays === 60) {
      setFilteredOrders(response?.data[0]?.last60Days?.reverse());
      setLoading(false)
    }
  };

  const fetchAllFixers = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}fixer/getallfixer`);
    setFixers(response.data)
  }

  useEffect(() => {
    fetchAllFixers()
    fetchOrdersOnDaysBasis();
  }, [activeDays]);

  useEffect(() => {
    if (filteredOrders.length > 0) {
      const categorizeOrders = (emailType, status1, status2) =>
        filteredOrders.filter((orderData) => {
          if (!orderData?.order?.email_status || orderData?.order?.email_status?.length === 0) {
            return false; // Skip orders with null or empty email_status
          }
          let statusLength = orderData?.order?.email_status?.length;
          let lastEmailStatus = orderData?.order?.email_status[statusLength - 1];
          return lastEmailStatus?.emailType === emailType && orderData.order.status !== status1 && orderData.order.status !== status2;
        });

      const categorizedOrders = {
        IntakeOrders: categorizeOrders("Intake", "archived", "onhold"),
        ItemPickedOrders: categorizeOrders("Item Picked", "archived", "onhold"),
        WithFixerOrder: categorizeOrders("With Fixer", "archived", "onhold"),
        ReadyForDeliveryOrders: categorizeOrders("Ready For Delivery", "archived", "onhold"),
        DeliveredOrders: categorizeOrders("Complete", "archived", "onhold"),
        ArchivedOrders: filteredOrders.filter((orderData) => orderData.order.status === "archived"),
        OnHoldOrders: filteredOrders.filter((orderData) => orderData.order.status === "onhold"),
      };

      setFilteredOrdersData(categorizedOrders);
    }
  }, [filteredOrders]);

  const mapOrdersToOrderDetails = (orders, stage) => {
    return orders.map((order) => ({
      id: order?.order_id_custom,
      name: order.order.customer_details?.name,
      price: `$${order.price}`,
      fixername: order?.fixer_details?.name,
      stage,
    }));
  };

  const orderDetails = [
    ...mapOrdersToOrderDetails(filteredOrdersData.IntakeOrders || [], "Intake"),
    ...mapOrdersToOrderDetails(filteredOrdersData.ItemPickedOrders || [], "Item Picked"),
    ...mapOrdersToOrderDetails(filteredOrdersData.WithFixerOrder || [], "With Fixer"),
    ...mapOrdersToOrderDetails(filteredOrdersData.ReadyForDeliveryOrders || [], "Ready For Delivery"),
    ...mapOrdersToOrderDetails(filteredOrdersData.DeliveredOrders || [], "Delivered"),
    ...mapOrdersToOrderDetails(filteredOrdersData.OnHoldOrders || [], "On Hold"),
    ...mapOrdersToOrderDetails(filteredOrdersData.ArchivedOrders || [], "Archived"),
  ];

  const overviewTabs = [
    "Intake",
    "Item Picked",
    "With Fixer",
    "Ready For Delivery",
    "Delivered",
    "On Hold",
    "Archived",
  ];


  return (
    <>
      {loading && (
        <div className="loader-popup">
          <div className="loader"></div>
        </div>
      )}
      <div className="tab-detail-outer-box items-listing-box">
        <div className="orders-overview-tabs desktop-none">
          {overviewTabs.map((tab) => (
            <div
              key={tab}
              className={`overview-tab ${selectedOverviewTab === tab ? "active" : ""}`}
              onClick={() => handleTabClick(tab)}
            >
              <h6>{tab}</h6>
            </div>
          ))}
        </div>
        <div className="head-box flex-box space-between align-center" style={{marginBottom : "1rem"}}>
          <h6 className="tab-detail-heading">
            {" "}
            <span>
              <img src={ordersIcon} alt="" />
            </span>{" "}
            All Orders Overview
          </h6>
          <div className="flex-box">
            <div className="field-box">
              <h6>Choose Fixer</h6>
              <div className="custom-dropdown">
                <select className="custom-select" onChange={(e) => setSelectedFixer(e.target.value)}>
                  <option value="null">All</option>
                  {fixers?.length != 0 && fixers.map((fixer) => (
                    <option value={fixer?.name?.toLowerCase().replace(' ', '')}>{fixer?.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="right-btn-box flex-box justify-center">
            <h6 className={`${activeDays == 7 ? 'active' : ''}`} onClick={() => setActiveDays(7)}>07 Days</h6>
            <h6 className={`${activeDays == 30 ? 'active' : ''}`} onClick={() => setActiveDays(30)}>30 Days</h6>
            <h6 className={`${activeDays == 60 ? 'active' : ''}`} onClick={() => setActiveDays(60)}>60 Days</h6>
          </div>
        </div>
        <>
          <ul className="all-overview-listing flex-box">
            {overviewTabs.map((tab) => (
              <li
                key={tab}
                className={`overview-inner-list ${selectedOverviewTab === tab ? "visible" : "hidden"
                  }`}
              >
                <div className="overview-name">
                  <h4>{tab}</h4>
                </div>
                {orderDetails
                  ?.filter((detail) => {
                    if(selectedFixer == null || selectedFixer == 'null'){
                      return detail.stage === tab;
                    } else {
                      return detail.stage === tab && detail?.fixername?.toLowerCase()?.replace(' ', '') == selectedFixer
                    }
                  })
                  ?.map((detail, index) => (
                    <div key={index} className="overview-detail">
                      <h6 className="order-id">
                        <strong>{detail.id}</strong>
                      </h6>
                      <h6 className="name">Name</h6>
                      <h6 className="customerorderdetailname">{detail.name}</h6>
                      <div className="price-and-no flex-box space-between">
                        <h6>{detail.price}</h6>
                        <h6>{detail?.serialNo}</h6>
                      </div>
                      <h6 className="name">Fixer Name</h6>
                      <h6 className="customerorderdetailname">{detail.fixername || "NA"}</h6>
                    </div>
                  ))}
              </li>
            ))}
          </ul>
        </>
      </div>
    </>
  );
}

export default AllOverviewTab;
