import React from "react";
import ItemDetail from "./ItemDetail";

function OrderItemsListing({ orders, tailors }) {
  const items = [
    {
      itemNo: "Item 01",
      title: "Premium, Alteration, Decrease Trouser Length",
      assignStatus: "Not Assigned",
    },
    {
      itemNo: "Item 02",
      title: "Standard, Repair, Skirt, Add A Patch",
      assignStatus: "Job Assigned",
    },
    {
      itemNo: "Item 03",
      title: "Premium, Alteration, Shirt",
      assignStatus: "Not Assigned",
    },
    {
      itemNo: "Item 04",
      title: "Standard, Repair, Skirt, Add A Patch",
      assignStatus: "Job Assigned",
    },
  ];

  console.log(orders, "aaa");

  return (
    <ul className="items-listing">
      {items.map((item, index) => (
        <ItemDetail
          key={index}
          itemNo={item.itemNo}
          title={item.title}
          assignStatus={item.assignStatus}
          tailors={tailors}
        />
      ))}
    </ul>
  );
}

export default OrderItemsListing;
