import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"
import csvIcon from "../../assets/img/export-csv-icon.webp"
const ExportExcel = ((exceldata,filename) => {
    const filetype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const filextension = ".xlsx";
    const exportExcel = async () => {
        // Check if exceldata is an array
        if (!Array.isArray(exceldata.exceldata)) {
            console.error("exceldata is not an array");
            return;
        }
        const ws = XLSX.utils.json_to_sheet(exceldata.exceldata);
        const wb = { Sheets : {'data' : ws}, SheetNames : ["data"] };
        const excelbuffer = XLSX.write(wb, { bookType : "xlsx", type : "array" })
        const data = new Blob([excelbuffer], {type : filetype});
        FileSaver.saveAs(data, exceldata.filename+filextension);
    }
    return (
        <>
        <h4 className='export-csv-btn' onClick={() => exportExcel(exceldata.filename)} >Export CSV <span><img src={csvIcon} alt="" /></span></h4>
        </>
    )
})

export default ExportExcel;