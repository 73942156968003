import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Confirm = ({ closeAddPopup, type, orderId }) => {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState('')
    const navigate = useNavigate()
    const handleSubmit = async () => {
        if (type != null) {
            setLoading(true)
            const res = await axios.put(`${process.env.REACT_APP_API_URL}order/activeoronhold/${orderId}`, { status: type, reason : reason });
            if (res.status == 200) {
                toast.success("Updated Successfully!")
                setLoading(false)
                closeAddPopup()
                navigate(`/${type}`)
            }
        } else {
            closeAddPopup()
        }
    }

    return (
        <>
            <div className="popup-outer-box">
                {loading && (
                    <div className="loader-popup">
                        <div className="loader"></div>
                    </div>
                )}
                <div className='popup-box user-edit-popup item-popup fixer-popup add-fixer-popup'>
                    {type == 'archived' &&
                        <div className="field-box">
                            <h4>Enter Reason *</h4>
                            <textarea className="popup-input full-width" rows={5} cols={5} onChange={(e) => setReason(e.target.value)}></textarea>
                        </div>
                    }
                    {type == 'onhold' && 
                        <div className="field-box">
                            <h4>Enter Reason *</h4>
                            <textarea className="popup-input full-width" rows={5} cols={5} onChange={(e) => setReason(e.target.value)}></textarea>
                        </div>
                    }
                    <div className="confirm-delete-box flex-box space-between align-center">
                        <h4>Are you sure you want to complete this action ?</h4>
                        <div className="yes-no-tab flex-box align-center">
                            <button type="button" onClick={handleSubmit} disabled={type == 'onhold' || type == 'archived' ? reason == '' ? true : false : false}>Yes</button>
                            <button onClick={closeAddPopup}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Confirm;