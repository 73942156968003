import { Constant } from "../Constant";

const initialState = {
    allDiscount: [],
    singleDiscount: ""
};

export const discountReducer = (state = initialState, action) => {
    switch (action.type) {
        case Constant.setDiscount:
            return { ...state, allDiscount : action.payload };
        default:
            return state;
    }
};
