import React from 'react'
import { Link } from 'react-router-dom';

function RecentOrders({ orderId, serviceName, orderDate, amount, status }) {

    let statusIcon = null;

    if (status === "In-Progress") {
        statusIcon = <span className="status-dot orange"></span>;
    } else if (status === "Completed") {
        statusIcon = <span className="status-dot green"></span>;
    }
    else if (status === "Pending") {
        statusIcon = <span className="status-dot red"></span>;
    }

    return (
        <li className="recent-order-detail-box">
            <Link className='recent-order-box flex-box space-between' >
                <div className="field-box">
                    <h6>ID</h6>
                    <h4>{orderId}</h4>
                </div>
                <div className="field-box">
                    <h6>Sub Order-IDs</h6>
                    {serviceName?.map((service,index) => (
                        <>
                            <h4>{`${orderId}-${index}`}</h4>
                        </>
                    ))

                    }
                    {/* <h4>{serviceName}</h4> */}
                </div>
                <div className="field-box">
                    <h6>Order Date</h6>
                    <h4>{orderDate}</h4>
                </div>
                <div className="field-box">
                    <h6>Amount</h6>
                    <h4>{amount}</h4>
                </div>
                <div className="field-box">
                    <h6>Status</h6>
                    <h4 className='relative' >{status}{statusIcon}</h4>
                </div>
            </Link>
        </li>
    );
}


export default RecentOrders