import React, { useState } from 'react'
import RecentOrders from './RecentOrders';
import { Link } from 'react-router-dom';
function calculateTotalSum(orderObject) {
  let totalSum = 0;
  orderObject?.services?.forEach((service) => {
    let pricing_plan;
    pricing_plan = orderObject?.order_items?.filter((item) => {
      if(item?.id == undefined){
        return item?.name.trim() == service?.items[0]?.name.trim();
      } else {
        return item?.id == service?.item_details?.id
      }
    });
    if (pricing_plan[0]?.pricing_plan == "Premium") {
      totalSum += parseFloat(service.premium_price);
    } else if (service.is_standard) {
      totalSum += parseFloat(service.standard_price);
    }
  });

  return totalSum;
}
function RecentOrdersListing({ orders }) {
  // 
  const [visibleItems, setVisibleItems] = useState(3);

  const handleLoadMore = () => {
    setVisibleItems(prev => prev + 3);
  };
  return (
    <>
      <ul className="recent-order-listing">
        <h6 className='tab-detail-heading' >Past Orders</h6>
        {orders.map((item, index) => {
          let total = calculateTotalSum(item) - item?.total_discount
          let salesTax = (10.35 / 100) * total;
          return (
            <RecentOrders
              key={index}
              orderId={item?.custom_order_id}
              serviceName={item?.services}
              orderDate={new Date(item.createdAt).toLocaleDateString()}
              amount={item?.delivery_address_type == "boutique" ? (total + salesTax).toFixed(2) : (total + salesTax + 5).toFixed(2)}
              status={item?.email_status && item?.email_status?.length != 0 ? item?.email_status[0]?.emailType : 'Pending'}
            />
          )
        }
        )}
      </ul>
      <h6 className='contact-develop-text' >*User can not be Edited or deleted from here, Contact your <Link>developer</Link></h6>
    </>
  )
}

export default RecentOrdersListing