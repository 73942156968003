import React, { useEffect, useState } from "react";
import payoutsIcon from "../../assets/img/menu-icon-7.webp";
import csvIcon from "../../assets/img/export-csv-icon.webp";
import PaidPayouts from "../module-tabs-detail/PaidPayout";
import ExportExcel from "../exports/Excelexport";
import axios from "axios";
import { useQuery } from "react-query";
import Loader from "../common/Loader";
import { useLocation } from "react-router-dom";

function AllPaidPayoutsTab() {
  const paidPayouts = [
    {
      fixerName: "Rebecca Smith",
      fixerImage: require("../../assets/img/fixer-image.webp"),
      fixerMail: "Jasithsmith@fxry.com",
      fixerContact: "+1(206) 555-3890",
      orderIdSku: "OD212 - SKU212A1",
      orderDate: "2nd April, Monday",
      completedOn: "10th April, Monday",
      status: "Completed",
      paidPayoutsList: [
        {
          paidSku: "SKU212A1",
          paidItem: "Trouser",
          paidCategory: "Standard",
          paidServices: "Alteration - Take In From Insides",
          paidAmount: "$ 40.00",
        },
      ],
    },
    {
      fixerName: "John Devis",
      fixerImage: require("../../assets/img/fixer-image.webp"),
      fixerMail: "Jasithsmith@fxry.com",
      fixerContact: "+1(206) 555-3890",
      orderIdSku: "OD212 - SKU212A1",
      orderDate: "2nd April, Monday",
      completedOn: "Work In Progress",
      status: "Pending",
      paidPayoutsList: [
        {
          paidSku: "SKU212A2",
          paidItem: "Trouser",
          paidCategory: "Standard",
          paidServices: "Alteration - Take In From Insides",
          paidAmount: "$ 40.00",
        },
      ],
    },
    {
      fixerName: "Emily Johnson",
      fixerImage: require("../../assets/img/fixer-image.webp"),
      fixerMail: "Jasithsmith@fxry.com",
      fixerContact: "+1(206) 555-3890",
      orderIdSku: "OD212 - SKU212A1",
      orderDate: "2nd April, Monday",
      completedOn: "10th April, Monday",
      status: "Completed",
      paidPayoutsList: [
        {
          paidSku: "SKU212A3",
          paidItem: "Trouser",
          paidCategory: "Standard",
          paidServices: "Alteration - Take In From Insides",
          paidAmount: "$ 40.00",
        },
      ],
    },
    {
      fixerName: "Sarah Brown",
      fixerImage: require("../../assets/img/fixer-image.webp"),
      fixerMail: "Jasithsmith@fxry.com",
      fixerContact: "+1(206) 555-3890",
      orderIdSku: "OD212 - SKU212A4",
      orderDate: "2nd April, Monday",
      completedOn: "10th April, Monday",
      status: "Completed",
      paidPayoutsList: [
        {
          paidSku: "SKU212A1",
          paidItem: "Trouser",
          paidCategory: "Standard",
          paidServices: "Alteration - Take In From Insides",
          paidAmount: "$ 40.00",
        },
      ],
    },
    {
      fixerName: "David Miller",
      fixerImage: require("../../assets/img/fixer-image.webp"),
      fixerMail: "Jasithsmith@fxry.com",
      fixerContact: "+1(206) 555-3890",
      orderIdSku: "OD212 - SKU212A1",
      orderDate: "2nd April, Monday",
      completedOn: "10th April, Monday",
      status: "Completed",
      paidPayoutsList: [
        {
          paidSku: "SKU212A5",
          paidItem: "Trouser",
          paidCategory: "Standard",
          paidServices: "Alteration - Take In From Insides",
          paidAmount: "$ 40.00",
        },
      ],
    },
  ];

  const getallfixerjobs = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}order/getalljobs`
    );
    return response.data;
  };

  const [filterData,setfilterData] = useState(null)
  const location = useLocation() 

  const { data, isLoading, refetch } = useQuery(
    "getallfixerjobs",
    getallfixerjobs, {
      onSuccess : (response) => {
        if(location.pathname === "/paid" && !isLoading){
          setfilterData(response?.filter((itm) => itm?.is_paid == "Paid")) 
        }
        if(location.pathname === "/pending" && !isLoading){
            setfilterData(response?.filter((itm) => itm?.is_paid == "Pending"))
        }
        if(location.pathname === "/dispute" && !isLoading){
            setfilterData(response?.filter((itm) => itm?.is_paid == "Dispute"))
        }
      }
    }
  );

  useEffect(() => {
    refetch()
  },[location.pathname])
  

  return (
    <div className="tab-detail-outer-box payouts-listing-box">
      <div className="tab-detail-head-box flex-box space-between">
        <h6 className="tab-detail-heading">
          {" "}
          <span>
            <img src={payoutsIcon} alt="" />
          </span>{" "}
          Fixer Payouts <strong>PAID</strong>{" "}
        </h6>
        <div className="right-box flex-box align-center">
          <ExportExcel filename={"Fixers Payout"} exceldata={paidPayouts} />
          <select>
            <option value="All Time">All Time</option>
            <option value="This Month">This Month</option>
            <option value="Past 3 Month">Past 3 Month</option>
          </select>
          <img className="sort-icon" src={csvIcon} alt="" />
        </div>
      </div>
      {isLoading && filterData == null ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <ul className="all-orders-listing">
            {filterData?.map((paidPayouts, index) => (
              <PaidPayouts key={index} paidPayouts={paidPayouts} data={data} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default AllPaidPayoutsTab;
