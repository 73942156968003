import { all, fork } from "redux-saga/effects";
import { watchServiceActions } from "./serviceSaga";
import { watchItemActions } from "./itemSaga";
import { watchFixerActions } from "./fixerSaga";
import { watchDiscountActions } from "./discountSaga";

export default function* rootSaga() {
  yield all([
    fork(watchServiceActions),
    fork(watchItemActions),
    fork(watchFixerActions),
    fork(watchDiscountActions)

  ]);
}
