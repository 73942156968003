import React, { useEffect, useState } from 'react';
import ordersIcon from '../../assets/img/menu-icon-1.webp';
import Order from '../module-tabs-detail/Order';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import ExportExcel from "../exports/Excelexport";

const pathURL = process.env.REACT_APP_API_URL;


const fetchCode = async () => {

    const { data } = await axios.get(`${pathURL}order/all`);
    return data;
};
function AllOrderTab() {
    const { data, error, isLoading, refetch } = useQuery({
        queryKey: ['fetchOrder'],
        queryFn: fetchCode,
    });
    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    let orders;
    orders = data?.map(item => ({ name: item.customer_id.name, status: item.status, itemCount: item.order_items.length, pickupDate: new Date(item.collection_date).toLocaleDateString(), paymentStatus: "Completed", items: item?.order_items?.map(el => el.selectedType).join(", "), orderId: item._id, item: item, assign_status: item.assign_status }))
    const location = useLocation()
    if (location.pathname === "/intake") {
        orders = orders?.filter((orderdata) => orderdata?.status == "intake");
    }

    if (location.pathname === "/picked") {
        orders = orders?.filter((orderdata) => orderdata?.status == "picked");
    }

    if (location.pathname === "/withfixer") {
        orders = orders?.filter((orderdata) => orderdata?.status == "withfixer");
    }

    if (location.pathname === "/delivered") {
        orders = orders?.filter((orderdata) => orderdata?.status == "delivered");
    }

    if (location.pathname === "/fixedandwithfxry") {
        orders = orders?.filter((orderdata) => orderdata?.status == "fixedandwithfxry");
    }

    if (location.pathname === "/readyfordelivery") {
        orders = orders?.filter((orderdata) => orderdata?.status == "readyfordelivery");
    }

    if (location.pathname === "/fixedbutwithfixer") {
        orders = orders?.filter((orderdata) => orderdata?.status == "fixedbutwithfixer");
    }

    if (location.pathname === "/archived") {
        orders = orders?.filter((orderdata) => orderdata.status === "archived");
    }

    if (location.pathname === "/onhold") {
        orders = orders?.filter((orderdata) => orderdata.status === "onhold");
    }
    let [exportOrdrData, setexportOrdrData] = useState(null)
    
    const fetchExportData = async () => {
        if (location.pathname === "/intake") {
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/intake`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }
    
        if (location.pathname === "/picked") {
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/picked`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }
    
        if (location.pathname === "/withfixer") {
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/withfixer`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }
    
        if (location.pathname === "/delivered") {
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/delivered`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }
    
        if (location.pathname === "/fixedandwithfxry") {
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/fixedandwithfxry`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }
    
        if (location.pathname === "/readyfordelivery") {
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/readyfordelivery`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }
    
        if (location.pathname === "/fixedbutwithfixer") {
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/fixedbutwithfixer`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }
    
        if (location.pathname === "/archived") {
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/archived`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }
    
        if (location.pathname === "/onhold") {
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/onhold`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }

        if(location.pathname === "/dashboard"){
            const response = await axios.get(`${pathURL}order/allorderexcelsheet/dashboard`);
            const exportJobs = (order) => {
                const { job, ...mainDetails } = order;
                return job?.map(jobDetails => ({ ...mainDetails, ...jobDetails }));
            };
            let exportData = response.data.flatMap(orderItem => exportJobs(orderItem));
            setexportOrdrData(exportData);
            return response.data;
        }
    }

    useEffect(() => {
        fetchExportData()
    }, [location.pathname])


    const exportOrdersData = useQuery({
        queryKey: ['fetchExportData'],
        queryFn: fetchExportData,
    });
    const [filteredOrders,setFilteredOrders] = useState([])
    useEffect(() => {
        if (searchTerm !== '') {
           orders = orders?.filter((customer) => {
                return customer?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || customer?.item?.custom_order_id == searchTerm
           });
           setFilteredOrders(orders)
        } else {
            setFilteredOrders([])   
        }
      }, [searchTerm]);

      const totalItems = orders?.reduce((acc, curr) => acc + curr.itemCount, 0)

    return (
        <div className="tab-detail-outer-box">
            <h6 className='tab-detail-heading'> <span><img src={ordersIcon} alt="" /></span> All Orders</h6>
            <h6>Total Orders : {orders?.length}</h6>
            <h6>Total Sub Orders : {totalItems}</h6>
            {/* <div style={{ display: "flex", justifyContent: "end", marginBottom: "0.8rem" }}>
            </div> */}
            <div className="right-box flex-box align-center" style={{ display: "flex", justifyContent: "end", marginBottom: "0.8rem" }}>
                <input
                    type="text"
                    placeholder="Search Orders..."
                    className="search-field"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                {!isLoading && (
                    <ExportExcel filename={"Orders"} exceldata={exportOrdrData} />
                )}
            </div>
            <ul className='all-orders-listing'>
                {filteredOrders && filteredOrders?.length == 0 &&
                    orders?.reverse()?.map((order, index) => (
                        <Order key={index} order={order} number={index} />
                    ))
                }
                {filteredOrders && filteredOrders?.length != 0 &&
                    filteredOrders?.reverse()?.map((order, index) => (
                        <Order key={index} order={order} number={index} />
                    ))
                }
            </ul>
        </div>
    );
}

export default AllOrderTab;
