export const Constant = {

  // ----Service
  addService: "ADD_SERVICE",
  fetchService: "FETCH_SERVICE",
  setService: "SET_SERVICE",
  deleteService: "DELETE_SERVICE",
  updateService: "UPDATE_SERVICE",
  fetchSingleService: "FETCH_SINGLE_SERVICE",
  setSingleService: "  SET_SINGLE_SERVICE",

  // --------Item
  setItem: "SET_ITEM",
  addItem: "ADD_ITEM",
  fetchItem: "FETCH_ITEM",
  deleteItem: "DELETE_ITEM",
  updateItem: "UPDATE_ITEM",

  // ---------Fixer
  setFixer: "SET_FIXER",
  addFixer: "ADD_FIXER",
  fetchFixer: "FETCH_FIXER",
  deleteFixer: "DELETE_FIXER",
  updateFixer: "UPDATE_FIXER",


  // ----- Discounts ----- //
  setDiscount: "SET_DISCOUNT",
  addDiscount: "ADD_DISCOUNT",
  fetchDiscount: "FETCH_DISCOUNT",
  deleteDiscount: "DELETE_DISCOUNT",
  updateDiscount: "UPDATE_DISCOUNT",

}

