import { Constant } from "../Constant";

const initialState = {
    allFixer: [],
    singleFixer: ""
};

export const fixerReducer = (state = initialState, action) => {
    switch (action.type) {
        case Constant.setFixer:
            return { ...state, allFixer: action.payload };
        default:
            return state;
    }
};
