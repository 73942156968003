import { Constant } from "../Constant";

export const addFixerAction = (value) => {
    return {
        type: Constant.addFixer,
        payload: value,
    };
};

export const updateFixerAction = (id, data) => {
    return {
        type: Constant.updateFixer,
        payload: { id, data },
    };
};

export const deleteFixerAction = (data) => {
    return {
        type: Constant.deleteFixer,
        payload: data,
    };
};

export const fetchFixerAction = (data) => {
    return {
        type: Constant.fetchFixer,
        payload: data,
    };
};

export const fetchSingleFixerAction = (data) => {
    return {
        type: Constant.fetchSingleFixer,
        payload: data,
    };
};
