import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MenuBox from "../components/common/MenuBox";
import OrderDetailTab from "../components/module-tabs-detail-page/OrderDetailTab";
import axios from "axios";
import { useQuery } from "react-query";

function OrderDetail() {
  const location = useLocation();
  const [selectedInnerTab, setSelectedInnerTab] = useState();
  const orderDetails =
    location.state || JSON.parse(sessionStorage.getItem("currentOrder")) || {};
  const fetchOrderDetail = async () => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }order/getonebyid/${location.pathname.replace(/.*\//, "")}`
    );
    return response.data[0];
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchOrderDetail"],
    queryFn: fetchOrderDetail,
  });

  useEffect(() => {
    refetch();
  }, [location.pathname.replace(/.*\//, "")]);

  return (
    <section className="main-dashboard-section">
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox selectedInnerTab={selectedInnerTab} />
          {!isLoading && (
            <OrderDetailTab
              setSelectedInnerTab={setSelectedInnerTab}
              itemCount={data?.order_items?.length || 0}
              items={data?.order_items}
              order={data}
              refetch={refetch}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default OrderDetail;
