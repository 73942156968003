import { put, takeLatest, call, all } from "redux-saga/effects";
import { Constant } from "../Constant";
import { adddiscount, deletediscount, fetchdiscount, updatediscount } from "../../server/discount";

function* fetchDiscountData() {
    try {
        const response = yield call(fetchdiscount);
        yield put({ type: Constant.setDiscount, payload: response?.data || [] });
    } catch (error) {
        return console.log(error);
    }
}




function* addDiscountData(action) {
    try {
        yield call(adddiscount, action.payload);
        yield call(fetchDiscountData);
    } catch (error) {
        return console.log(error);
    }
}

function* updateDiscountData(action) {
    let { id, data } = action.payload;
    try {
        yield call(updatediscount, id, data);
        yield call(fetchDiscountData);
    } catch (error) {
        console.log(error);
    }
}

function* deleteDiscountData(action) {
    try {
        yield call(deletediscount, action.payload);
        yield call(fetchDiscountData);
    } catch (error) {
        console.log(error);
    }
}

export function* watchDiscountActions() {
    yield all([
        takeLatest(Constant.fetchDiscount, fetchDiscountData), takeLatest(Constant.addDiscount, addDiscountData), takeLatest(Constant.updateDiscount, updateDiscountData), takeLatest(Constant.deleteDiscount, deleteDiscountData),])

}