import axios from "axios"
const pathURL = process.env.REACT_APP_API_URL;

export async function fetchService(data) {
    try {
        return await axios.get(
            `${pathURL}service/getallservice`,
        );
    } catch (error) {
        return error.response;
    }
}

export async function fetchSingleService(id) {
    try {
        return await axios.get(
            `${pathURL}service/getoneservice/${id}`,
        );
    } catch (error) {
        return error.response;
    }
}

export async function addService(data) {
    try {
        return await axios.post(`${pathURL}service/createservice`, data,);
    } catch (error) {
        return error.response;
    }
}

export async function updateService(id, data) {
    try {
        return await axios.patch(
            `${pathURL}service/updateservice/${id}`,
            data,
        );
    } catch (error) {
        return error.response;
    }
}

export async function deleteService(id) {
    try {
        return await axios.delete(
            `${pathURL}service/deleteservice/${id}`
        );
    } catch (error) {
        return error.response;
    }
}