import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/App.css'
import myStore from "./Redux/Store/store"
import { Provider } from "react-redux";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={myStore}>
    <App />
  </Provider>
);
