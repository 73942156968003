import { Constant } from "../Constant";

export const addItemAction = (value) => {
  return {
    type: Constant.addItem,
    payload: value,
  };
};

export const fetchItemAction = (data) => {
  return {
    type: Constant.fetchItem,
    payload: data,
  };
};

export const fetchSingleItemAction = (data) => {
  return {
    type: Constant.fetchSingleItem,
    payload: data,
  };
};

export const deleteItemAction = (data) => {
  return {
    type: Constant.deleteItem,
    payload: data,
  };
};

export const updateItemAction = (id, data) => {
  return {
    type: Constant.updateItem,
    payload: { id, data },
  };
};
