import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ctaArrow from '../../assets/img/cta-arrow.webp';
import popupCloseIcon from '../../assets/img/popup-close-icon.webp';
import itemDelBtn from '../../assets/img/item-del-btn.webp';
import axios from 'axios';
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';
function ItemTab({ title, value }) {
    return (
        <div className="order-detail-box">
            <h6>{title}</h6>
            <h4>{value || '-'}</h4>
        </div>
    );
}

function Discount({ discount, fetchDiscountAction }) {
    const { discountName, discountType, discountCategory, discountAmount, discountStart, discountEnd, discountUsage, code } = discount;
    const [showMainPopup, setShowMainPopup] = useState(false);
    const dispatch = useDispatch();
    const openMainPopup = () => {
        setShowMainPopup(true);
    };
    const closeMainPopup = () => {
        setShowMainPopup(false);
    };
    const navigate = useNavigate()
    const handeDiscountDelete = async (id) => {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}discounts/deletediscount/${id}`);
        if (response.status == 200) {
            toast.success("Deleted Successfully!");
            dispatch(fetchDiscountAction())
            closeMainPopup()
            navigate("/discounts")
        }
    };


    return (
        <li>
            <Link
                onClick={openMainPopup}
                className='order-box flex-box align-center space-between'>
                <ItemTab title="Name" value={discountName} />
                <ItemTab title="Type" value={discountType} />
                <ItemTab title="Code" value={code} />
                <ItemTab title="Amount" value={discountAmount} />
                <ItemTab title="Start" value={discountStart} />
                {/* <ItemTab title="End" value={discountEnd} /> */}
                <div className="order-cta-box">
                    <img src={ctaArrow} alt="" />
                </div>
            </Link>
            {showMainPopup && (
                <div className="popup-outer-box">
                    <div className="popup-box user-edit-popup item-popup discount-popup">
                        <div className="edit-and-delete-btn flex-box align-center">
                            <img className='item-del-btn' src={itemDelBtn} onClick={() => handeDiscountDelete(discount.code)} alt="" />
                        </div>
                        <button className='popup-close-btn' onClick={closeMainPopup}><img src={popupCloseIcon} alt="" /></button>
                        <div className="popup-edit-box flex-box space-between">
                            <div className="field-box w-50">
                                <h6>Name</h6>
                                <h4>{discountName || '-'}</h4>
                            </div>
                            <div className="field-box w-50">
                                <h6>Type</h6>
                                <h4>{discountType || '-'}</h4>
                            </div>
                            <div className="field-box w-50">
                                <h6>Category</h6>
                                <h4>{discountCategory || '-'}</h4>
                            </div>
                            <div className="field-box w-50">
                                <h6>Amount</h6>
                                <h4>{discountAmount || '-'}</h4>
                            </div>
                            <div className="field-box w-50">
                                <h6>Start</h6>
                                <h4>{discountStart || '-'}</h4>
                            </div>
                            <div className="field-box w-50">
                                <h6>End</h6>
                                <h4>{discountEnd || '-'}</h4>
                            </div>
                            <div className="field-box w-50">
                                <h6>Code</h6>
                                <h4>{code || '-'}</h4>
                            </div>
                            <div className="field-box w-50">
                                <h6>Usage</h6>
                                <h4>{discountUsage || '-'}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </li>
    );
}

export default Discount