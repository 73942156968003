import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ctaArrow from '../../assets/img/cta-arrow.webp';
import popupCloseIcon from '../../assets/img/popup-close-icon.webp';
import changeImg from '../../assets/img/user-change-img.webp';
import itemEditBtn from '../../assets/img/item-edit-btn.webp';
import itemDelBtn from '../../assets/img/item-del-btn.webp';
import emptyImage from '../../assets/img/empty-img.webp'
import copyIcon from '../../assets/img/copy-icon.png';
import { useDispatch } from 'react-redux';
import { deleteFixerAction, fetchFixerAction, updateFixerAction } from '../../Redux/Action/fixerAction';
import { deleteFixer } from '../../server/fixer';
import EditFixer from '../popup/EditFixer';

function ItemTab({ title, value }) {
    return (
        <div className="order-detail-box">
            <h6>{title}</h6>
            <h4>{value}</h4>
        </div>
    );
}

function Fixer({ fixer, refetch }) {
    const dispatch = useDispatch()
    const { fixerName, companyName, address, mobileNumber, jobsCompleted, emailAddress, userName, fixerImage, inProgress } = fixer;
    const popupRef = useRef()
    const [showMainPopup, setShowMainPopup] = useState(false);
    const [popupClass, setPopupClass] = useState('popup-box user-edit-popup item-popup fixer-popup ');
    const [userImg, setUserImg] = useState('');
    const [newEditName, setNewEditName] = useState('');
    const [newEditComapanyName, setNewEditComapanyName] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [newMobileNumber, setNewMobileNumber] = useState('');
    const [newEmailNumber, setNewEmailNumber] = useState('');
    const [newAddress, setNewAddress] = useState('');

    const openMainPopup = () => {
        setShowMainPopup(true);
    };
    const closeMainPopup = () => {
        setShowMainPopup(false);
        setPopupClass('popup-box user-edit-popup item-popup fixer-popup');
    };
    const openItemEditPopup = () => {
        setPopupClass('popup-box user-edit-popup item-popup fixer-popup item-edit-popup');
    };
    const openItemDelPopup = () => {
        setPopupClass('popup-box user-edit-popup item-popup fixer-popup item-del-popup');
    };
    const handleNameChange = (e) => {
        setNewEditName(e.target.value);
    };
    const handleComapanyNameChange = (e) => {
        setNewEditComapanyName(e.target.value);
    };
    const handleUserNameChange = (e) => {
        setNewUserName(e.target.value);
    };
    const handleMobileChange = (e) => {
        setNewMobileNumber(e.target.value);
    };
    const handleEmailChange = (e) => {
        setNewEmailNumber(e.target.value);
    };
    const handleAddressChange = (e) => {
        setNewAddress(e.target.value);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                closeMainPopup()
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);
    const updateFixerHandler = () => {
        dispatch(updateFixerAction(fixer.id, { name: newEditName, company_name: newEditComapanyName, email: newEmailNumber, phone: newMobileNumber, address: newAddress, }))
        setShowMainPopup(false);
    }

    return (
        <li>
            <Link
                onClick={openMainPopup}
                className='order-box flex-box align-center space-between'>
                <ItemTab title="Full Name" value={fixerName} />
                <ItemTab title="Company Name" value={companyName} />
                <ItemTab title="Address" value={address} />
                <ItemTab title="Mobile Number" value={mobileNumber} />
                {/* <ItemTab title="Jobs Completed" value={jobsCompleted} /> */}
                <div className="order-cta-box">
                    <img src={ctaArrow} alt="" />
                </div>
            </Link>
            {showMainPopup && (
                <>
                    <EditFixer refetch={refetch} closeAddPopup={closeMainPopup} popupCloseIcon={popupCloseIcon} userImg={userImg} emptyImage={emptyImage} changeImg={changeImg} copyIcon={copyIcon} fixer={fixer} />
                </>
            )}
        </li>
    );
}

export default Fixer