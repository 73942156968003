import React from 'react'
import CustomerDetailBox from '../customers/CustomerDetailBox';
import RecentOrdersListing from '../customers/RecentOrdersListing';

function CustomerDetailTab({ customerName, totalOrder, totalAmountSpend, customer,orders, allJobs }) {
  return (
    <div className="tab-detail-outer-box">
        <CustomerDetailBox customerName={customerName} totalOrder={totalOrder} totalAmountSpend={totalAmountSpend} customer={customer}/>
        <RecentOrdersListing orders={orders}  />
    </div>
  )
}

export default CustomerDetailTab