import { put, takeLatest, call, all } from "redux-saga/effects";
import { Constant } from "../Constant";
import {
  addService,
  deleteService,
  fetchService,
  updateService,
} from "../../server/service";

function* fetchServiceData() {
  try {
    const response = yield call(fetchService);
    yield put({ type: Constant.setService, payload: response?.data || [] });
  } catch (error) {
    return console.log(error);
  }
}

function* addServiceData(action) {
  try {
    yield call(addService, action.payload);
    yield call(fetchServiceData);
  } catch (error) {
    return console.log(error);
  }
}

function* updateServiceData(action) {
  let { id, data } = action.payload;
  try {
    yield call(updateService, id, data);
    yield call(fetchServiceData);
  } catch (error) {
    console.log(error);
  }
}

function* deleteServiceData(action) {
  try {
    yield call(deleteService, action.payload);
    yield call(fetchServiceData);
  } catch (error) {
    console.log(error);
  }
}

export function* watchServiceActions() {
  yield all([
    takeLatest(Constant.fetchService, fetchServiceData),
    takeLatest(Constant.addService, addServiceData),
    takeLatest(Constant.updateService, updateServiceData),
    takeLatest(Constant.deleteService, deleteServiceData),
  ]);
}
