import React, { useEffect, useState } from "react";
import customersIcon from "../../assets/img/menu-icon-2.webp";
import Customers from "../module-tabs-detail/Customers";
import csvIcon from "../../assets/img/export-csv-icon.webp";
import axios from "axios";
import { useQuery } from "react-query";
import ExportExcel from "../exports/Excelexport";
import Loader from "../common/Loader";

function calculateTotalSum(orderObject) {
  let totalSum = 0;
  orderObject?.services?.forEach((service) => {
      let pricing_plan = orderObject?.order_items?.filter(
          (item) => item?.name.trim() == service?.items[0]?.name.trim()
      );
      if (pricing_plan[0]?.pricing_plan == "Premium") {
          totalSum += parseFloat(service.premium_price);
      } else if (service.is_standard) {
          totalSum += parseFloat(service.standard_price);
      }
  });
  return totalSum;
}

function AllCustomersTab() {

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const fetchAllCustomers = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}users/allcustomers`);
    return response.data;
  };

  const [customersData,setCustomersData] = useState([])

  const [excelExport,setexcelExport] = useState(null);

  const { data, isLoading } = useQuery("fetchAllCustomers", fetchAllCustomers, {
    onSuccess : (customers) => {
        let exportedcustomers = customers.map((custom) => {
        let totalPrice = 0;
        let salesTax = 0;
        custom?.orders?.map((orderData) => {
            let totalSum = calculateTotalSum(orderData);
            totalPrice += totalSum;
        })
        salesTax = (10.35 / 100) * totalPrice;
        setCustomersData(customers?.reverse());
        setFilteredCustomers(customers?.reverse());
        return (
          {
            'Name' : custom?.name,
            'Email' : custom?.email,
            'Phone' : custom?.phone,
            'Total Orders' : custom?.orders?.length,
            'Address' : custom?.address,
            'Total Price' : (totalPrice + salesTax + 5)?.toFixed(2)
          }
        )
      })
      setexcelExport(exportedcustomers)
    }
  });

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredCustomers(customersData);
    } else {
      const filtered = customersData?.filter((customer) => customer?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
      setFilteredCustomers(filtered);
    }
  }, [searchTerm, customersData]);

  return (
    <div className="tab-detail-outer-box customers-listing-box">
      <div className="tab-detail-head-box flex-box space-between">
        <h6 className="tab-detail-heading">
          {" "}
          <span>
            <img src={customersIcon} alt="" />
          </span>{" "}
          All Customers
        </h6>
        <h6>Total Customers : {filteredCustomers?.length}</h6>
        <div className="right-box flex-box align-center search-icon">
          <input
            type="text"
            placeholder="Search Customer..."
            className="search-field"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {!isLoading && (
            <ExportExcel filename={"customers"} exceldata={excelExport?.reverse()} />
          )}
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ul className="all-orders-listing">
            {filteredCustomers?.reverse()?.map((customer, index) => (
              <Customers key={index} customer={customer} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default AllCustomersTab;
