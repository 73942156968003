import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";

const AddFixer = ({
    closeAddPopup,
    popupCloseIcon,
    userImg,
    emptyImage,
    changeImg,
    copyIcon,
    createFixerHandler,
    refetch
}) => {

    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = useState({
        newFixerName: '',
        newCompanyName: '',
        newUserPassword: '',
        newFixerMobileNumber: '',
        newFixerEmail: '',
        newFixerAddress: '',
        inputValues: [''],
        userFile: null,
        userImg: userImg || emptyImage,
    });
    const handleChange = (field, value) => {
        setFormState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleInputChange = (index, value) => {
        const newInputValues = [...formState.inputValues];
        newInputValues[index] = value;
        setFormState((prevState) => ({
            ...prevState,
            inputValues: newInputValues,
        }));
    };

    const handleAddInput = () => {
        setFormState((prevState) => ({
            ...prevState,
            inputValues: [...prevState.inputValues, ''],
        }));
    };

    const copyPasswordToClipboard = () => {
        navigator.clipboard.writeText(formState.newUserPassword);
    };

    const validateForm = () => {
        const { newFixerName, newUserPassword, newFixerMobileNumber, newFixerEmail, userFile, newFixerAddress } = formState;
        if (!newFixerName.trim()) {
            toast.error('Please enter the fixer name.');
            return false;
        }
        if (!newUserPassword.trim()) {
            toast.error('Please enter the password.');
            return false;
        }
        if (!/^\+?\d{1,15}$/.test(newFixerMobileNumber)) {
            toast.error('Please enter a valid mobile number.');
            return false;
        }
        if (!/^\S+@\S+\.\S+$/.test(newFixerEmail)) {
            toast.error('Please enter a valid email address.');
            return false;
        }
        // if (!userFile) {
        //     toast.error('Please upload a file.');
        //     return false;
        // }
        // if (!newFixerAddress.trim()) {
        //     toast.error('Please enter the address.');
        //     return false;
        // }
        return true;
    };

    const generateUsername = (name, email, phone) => {
        const namePart = name;
        const emailPart = email.split('@')[0];
        const emailDigits = emailPart.replace(/\D/g, '').slice(0, 2);
        const phoneDigits = phone.slice(0, 2);
        const username = `${namePart}${emailDigits}${phoneDigits}`;
        return username.toLowerCase();
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true)
            const { newFixerName, newUserPassword, newCompanyName, inputValues, newFixerMobileNumber, newFixerEmail, userFile, newFixerAddress } = formState;
            const formData = new FormData()
            formData.append("fixerUpload", userFile)
            const username = generateUsername(newFixerName, newFixerEmail, newFixerMobileNumber)
            formData.append("data", JSON.stringify({
                name: newFixerName,
                username: username.replace(' ', ''),
                company_name: newCompanyName,
                password: newUserPassword,
                email: newFixerEmail,
                phone: newFixerMobileNumber,
                address: newFixerAddress,
                speciality: inputValues
            }));
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}fixer/createfixer`, formData);
            if (resp.status == 200) {
                toast.success("Created Successfully!")
                refetch()
                setLoading(false)
                closeAddPopup()
            }
        }
    };

    return (
        <>
            <div className="popup-outer-box">
                {loading && (
                    <div className="loader-popup">
                        <div className="loader"></div>
                    </div>
                )}
                <div className='popup-box user-edit-popup item-popup fixer-popup add-fixer-popup'>
                    <button className='popup-close-btn' onClick={closeAddPopup}>
                        <img src={popupCloseIcon} alt="" />
                    </button>
                    <div className="popup-edit-box flex-box space-between">
                        <div className="img-change-box relative">
                            <img className='user-edit-img' src={formState.userImg} alt="" />
                            <img
                                className='user-change-img'
                                src={changeImg}
                                alt=""
                                onClick={() => document.getElementById('file-input').click()}
                            />
                            <input
                                id="file-input"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    handleChange('userFile', file);
                                    if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                            handleChange('userImg', event.target.result);
                                        };
                                        reader.readAsDataURL(file);
                                    }
                                }}
                            />
                        </div>
                        <div className="user-text-box">
                            <div className="field-box">
                                <h6>Fixer Name</h6>
                                <input
                                    className='popup-input'
                                    value={formState.newFixerName}
                                    onChange={(e) => handleChange('newFixerName', e.target.value)}
                                    type="text"
                                />
                            </div>
                            <div className="field-box">
                                <h6>Company Name</h6>
                                <input
                                    className='popup-input'
                                    value={formState.newCompanyName}
                                    onChange={(e) => handleChange('newCompanyName', e.target.value)}
                                    type="text"
                                />
                            </div>
                            <div className="field-box">
                                <div className="box-1">
                                    <h6>Set Password</h6>
                                    <div className="password-box flex-box relative">
                                        <input
                                            className='popup-input'
                                            value={formState.newUserPassword}
                                            onChange={(e) => handleChange('newUserPassword', e.target.value)}
                                            type="password"
                                        />
                                        <img className='copy-icon' onClick={copyPasswordToClipboard} src={copyIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup-down-box flex-box">
                        <div className="field-box w-50">
                            <h6>Mobile Number</h6>
                            <input
                                className='popup-input'
                                value={formState.newFixerMobileNumber}
                                onChange={(e) => handleChange('newFixerMobileNumber', e.target.value)}
                                type="number"
                                placeholder="+1(206) 555-3890"
                            />
                        </div>
                        <div className="field-box w-50">
                            <h6>Email Address</h6>
                            <input
                                className='popup-input w-100'
                                value={formState.newFixerEmail}
                                onChange={(e) => handleChange('newFixerEmail', e.target.value)}
                                type="email"
                            />
                        </div>
                        <div className="field-box w-100">
                            <h6>Address</h6>
                            <input
                                className='popup-input'
                                value={formState.newFixerAddress}
                                onChange={(e) => handleChange('newFixerAddress', e.target.value)}
                                type="text"
                            />
                        </div>
                        <div className="field-box w-100 mt6">
                            <h6>Specialty</h6>
                            <div className="specialty-box flex-box">
                                {formState.inputValues.map((value, index) => (
                                    <input
                                        key={index}
                                        className='popup-input'
                                        type="text"
                                        placeholder="Perfect Fit"
                                        value={value}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                    />
                                ))}
                                {formState.inputValues.length < 4 && (
                                    <button onClick={handleAddInput}><strong>+</strong> Add</button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="bottom-save-box flex-box space-between align-center" onClick={handleSubmit}>
                        <button className='save-info-btn' disabled={loading}>Save</button>
                    </div>
                    <div className="confirm-delete-box flex-box space-between align-center" style={{ display: 'none' }}>
                        <h4>Are you sure you want to delete this?</h4>
                        <div className="yes-no-tab flex-box align-center">
                            <button>Yes</button>
                            <button onClick={closeAddPopup}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddFixer;