import React, { useEffect, useRef, useState } from "react";
import ordersIcon from "../../assets/img/menu-icon-1.webp";
import addNewIcon from "../../assets/img/add-new-btn-icon.webp";
import Item from "../module-tabs-detail/Item";
import changeImg from "../../assets/img/user-change-img.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import emptyImg from "../../assets/img/empty-img.webp";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { addItemAction, fetchItemAction } from "../../Redux/Action/itemAction";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import Loader from "../common/Loader";
import toast from "react-hot-toast";

function AllItemsTab() {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.itemReducer.allItem);
  const popupRef = useRef(null);
  const [itemImage, setItemImage] = useState();
  //   const [items, setItems] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [userImg, setUserImg] = useState("");
  const [newItemName, setNewItemName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const today = new Date();
  const formattedDate = format(today, "do MMMM, EEEE");
  const queryClient = useQueryClient();
  const openAddPopup = () => {
    setNewItemName("");
    setItemImage();
    setUserImg("");
    setShowAddPopup(true);
  };
  const closeAddPopup = () => {
    setShowAddPopup(false);
  };
  const handleNewName = (e) => {
    setNewItemName(e.target.value);
  };

  const createItemHandler = async () => {
    if (itemImage && newItemName) {
      const formData = new FormData();
      formData.append("itemUpload", itemImage);
      formData.append("data", JSON.stringify({ name: newItemName }));
      dispatch(addItemAction(formData));
      dispatch(fetchItemAction());
      closeAddPopup();
      toast.success("Created Successfully!");
    } else {
      toast.error("Please add image and name!")
    }
  };

  useEffect(() => {
    dispatch(fetchItemAction());
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [items]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowAddPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return (
    <div className="tab-detail-outer-box items-listing-box">
      <div className="head-box flex-box space-between align-center">
        <h6 className="tab-detail-heading">
          {" "}
          <span>
            <img src={ordersIcon} alt="" />
          </span>{" "}
          All Items
        </h6>
        <h6 className="add-new-btn" onClick={openAddPopup}>
          {" "}
          <span>
            <img src={addNewIcon} alt="" />
          </span>{" "}
          Add New Items
        </h6>
        {showAddPopup && (
          <div className="popup-outer-box">
            <div
              ref={popupRef}
              className="popup-box user-edit-popup item-popup add-new-item-poprp"
            >
              <button className="popup-close-btn" onClick={closeAddPopup}>
                <img src={popupCloseIcon} alt="" />
              </button>
              <div className="popup-edit-box flex-box space-between">
                <div className="img-change-box relative">
                  <img
                    className="user-edit-img"
                    src={userImg || emptyImg}
                    alt=""
                  />
                  <img
                    className="user-change-img"
                    src={changeImg}
                    alt=""
                    onClick={() =>
                      document.getElementById("file-input").click()
                    }
                  />
                  <input
                    id="file-input"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setItemImage(file);

                      if (file) {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                          const imageDataUrl = event.target.result;
                          setUserImg(imageDataUrl);
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                </div>
                <div className="user-text-box">
                  <div className="field-box">
                    <h6>Item Name</h6>
                    <input
                      className="popup-input"
                      value={newItemName}
                      onChange={handleNewName}
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div className="field-box">
                    <h6>Added On</h6>
                    <h4>{formattedDate}</h4>
                  </div>
                </div>
              </div>

              <div
                className="bottom-save-box flex-box space-between align-center"
                onClick={() => createItemHandler()}
              >
                <button className="save-info-btn">Save</button>
              </div>
            </div>
          </div>
        )}
      </div>
      {isLoading && items.length <= 0 ? (
        <Loader />
      ) : (
        <>
          <ul className="all-orders-listing">
            {items?.map((item, index) => {
              item = {
                id: item._id,
                itemName: item.name,
                associatedServices: "18",
                addedOn: new Date(item.createdAt).toDateString(),
                itemIcon: item.image,
              };
              return <Item key={item.id} item={item} />;
            })}
          </ul>
        </>
      )}
    </div>
  );
}

export default AllItemsTab;
