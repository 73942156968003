import axios from "axios"
const pathURL = process.env.REACT_APP_API_URL;

export async function fetchdiscount(data) {
    try {
        return await axios.get(
            `${pathURL}discounts/getalldiscount`,
        );
    } catch (error) {
        return error.response;
    }
}

export async function fetchSinglediscount(id) {
    try {
        return await axios.get(
            `${pathURL}discounts/getonediscount/${id}`,
        );
    } catch (error) {
        return error.response;
    }
}

export async function adddiscount(data) {
    try {
        return await axios.post(`${pathURL}discounts/creatediscount`, data,);
    } catch (error) {
        return error.response;
    }
}

export async function updatediscount(id, data) {
    try {
        return await axios.patch(
            `${pathURL}discounts/updatediscount/${id}`,
            data,
        );
    } catch (error) {
        return error.response;
    }
}

export async function deletediscount(id) {
    try {
        return await axios.delete(
            `${pathURL}discounts/deletediscount/${id}`
        );
    } catch (error) {
        return error.response;
    }
}