import { put, takeLatest, call, all } from "redux-saga/effects";
import { Constant } from "../Constant";
import { addItem, deleteItem, fetchItem, updateItem } from "../../server/item";

function* fetchItemData() {
  try {
    const response = yield call(fetchItem);
    yield put({ type: Constant.setItem, payload: response?.data || [] });
  } catch (error) {
    return console.log(error);
  }
}

function* addItemData(action) {
  try {
    yield call(addItem, action.payload);
    yield call(fetchItemData);
  } catch (error) {
    return console.log(error);
  }
}

function* updateItemData(action) {
  let { id, data } = action.payload;
  try {
    yield call(updateItem, id, data);
    yield call(fetchItemData);
  } catch (error) {
    console.log(error);
  }
}

function* deleteItemData(action) {
  try {
    yield call(deleteItem, action.payload);
    yield call(fetchItemData);
  } catch (error) {
    console.log(error);
  }
}

export function* watchItemActions() {
  yield all([
    takeLatest(Constant.fetchItem, fetchItemData),
    takeLatest(Constant.addItem, addItemData),
    takeLatest(Constant.updateItem, updateItemData),
    takeLatest(Constant.deleteItem, deleteItemData),
  ]);
}
