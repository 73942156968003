import React, { useEffect, useState } from 'react'
import servicesIcon from '../../assets/img/job-status-fixer-icon.webp'
import addNewIcon from '../../assets/img/add-new-btn-icon.webp';
import Fixer from '../module-tabs-detail/Fixers';
import changeImg from '../../assets/img/user-change-img.webp';
import emptyImage from '../../assets/img/empty-img.webp'
import popupCloseIcon from '../../assets/img/popup-close-icon.webp';
import copyIcon from '../../assets/img/copy-icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { addFixerAction, fetchFixerAction } from '../../Redux/Action/fixerAction';
import { fetchItemAction } from '../../Redux/Action/itemAction';
import Loader from '../common/Loader';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import AddFixer from '../popup/AddFixer';



function AllFixersTab() {
    const dispatch = useDispatch()
    const allFixer = useSelector((state) => state.fixerReducer.allFixer)
    const allItem = useSelector((state) => state.itemReducer.allItem)
    const navigate = useNavigate()
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [userImg, setUserImg] = useState('');
    const [userFile, setUserFile] = useState()
    const [newFixerName, setNewFixerName] = useState('');
    const [newComapanyName, setNewComapanyName] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('fixer@123');
    const [newFixerMobileNumber, setNewFixerMobileNumber] = useState('');
    const [newFixerEmail, setNewFixerEmail] = useState('');
    const [newFixerAddress, setNewFixerAddress] = useState('');
    const [inputValues, setInputValues] = useState(['']);

    const openAddPopup = () => {
        setShowAddPopup(true);
    };
    const closeAddPopup = () => {
        setShowAddPopup(false);
        refetch()
    };
    const handleNewName = (e) => {
        setNewFixerName(e.target.value);
    };
    const handleNewComapanyName = (e) => {
        setNewComapanyName(e.target.value);
    };
    const handleNewUserPassword = (e) => {
        setNewUserPassword(e.target.value);
    };
    const copyPasswordToClipboard = () => {
        navigator.clipboard.writeText(newUserPassword);
    };
    const handleNewMobileNumber = (e) => {
        setNewFixerMobileNumber(e.target.value);
    };
    const handleNewEmail = (e) => {
        setNewFixerEmail(e.target.value);
    };
    const handleNewAddress = (e) => {
        setNewFixerAddress(e.target.value);
    };
    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };
    const handleAddInput = () => {
        if (inputValues.length < 4) {
            setInputValues([...inputValues, '']);
        }
    };

    const fetchAllFixers = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}fixer/getallfixer`)
        return response.data;
    }

    const {
         data , isLoading, refetch
    } = useQuery("fetchAllFixers", fetchAllFixers)

    // useEffect(() => {
    //     refetch()
    // },[refetch])

    const generateUsername = (name, email, phone) => {
        const namePart = name;
        const emailPart = email.split('@')[0];
        const emailDigits = emailPart.replace(/\D/g, '').slice(0, 2);
        const phoneDigits = phone.slice(0, 2);
        const username = `${namePart}${emailDigits}${phoneDigits}`;
        return username.toLowerCase();
    };
    
    const createFixerHandler = () => {
        if(!newFixerName.trim()){
            toast.error("Please enter fixer name")
        }
        // if(!newComapanyName.trim()){
        //     toast.error("Please enter company name")
        // }
        if(!newUserPassword.trim()){
            toast.error("Please enter fixer password")
        }
        if(!newFixerEmail.trim()){
            toast.error("Please enter fixer email")
        }
        if(!newFixerMobileNumber.trim()){
            toast.error("Please enter fixer mobile")
        }
        const formData = new FormData()
        formData.append("fixerUpload", userFile)
        const username = generateUsername(newFixerName, newFixerEmail, newFixerMobileNumber)
        formData.append("data", JSON.stringify({ name: newFixerName, username : username.replace(' ', ''), company_name: newComapanyName, password: newUserPassword, email: newFixerEmail, phone: newFixerMobileNumber, address: newFixerAddress, speciality: inputValues }))
        dispatch(addFixerAction(formData))
        navigate('/fixers')
        refetch()
        closeAddPopup()
        // dispatch(fetchFixerAction())
    }
   
    return (
        <div className="tab-detail-outer-box fixes-listing-box">
            <div className="head-box flex-box space-between align-center">
                <h6 className='tab-detail-heading'> <span><img src={servicesIcon} alt="" /></span> Fixers Listing</h6>
                <h6 className='add-new-btn' onClick={openAddPopup} > <span><img src={addNewIcon} alt="" /></span> Add Fixer</h6>
                {showAddPopup && (
                    <AddFixer closeAddPopup={closeAddPopup} popupCloseIcon={popupCloseIcon} userImg={userImg} emptyImage={emptyImage} changeImg={changeImg} copyIcon={copyIcon} createFixerHandler={createFixerHandler} refetch={refetch} />
                )}
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <ul className='all-orders-listing'>
                            {data?.map((fixers,index) => {
                                const newFixer = {
                                    id: fixers?._id,
                                    fixerName: fixers?.name,
                                    companyName: fixers?.company_name,
                                    address: fixers?.address,
                                    mobileNumber: fixers?.phone,
                                    // jobsCompleted: "20",
                                    // inProgress: "05",
                                    fixerImage: fixers?.image,
                                    userName : fixers?.username || "Jasith01",
                                    emailAddress: fixers?.email,
                                    speciality : fixers?.speciality || []
                                }
                                return (
                                    <Fixer key={index} fixer={newFixer} refetch={refetch} />
                                )
                            })}
                    </ul>
                </>
            )}
        </div>
    );
}

export default AllFixersTab