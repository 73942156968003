import React from 'react'
import { Link } from 'react-router-dom';
import ctaArrow from '../../assets/img/cta-arrow.webp';

function calculateTotalSum(orderObject) {
    let totalSum = 0;
    orderObject?.services?.forEach((service) => {
        let pricing_plan;
        if(service?.item_details?.id == undefined){
            pricing_plan = orderObject?.order_items?.filter((item) => item?.name.trim() == service?.items[0]?.name.trim());
        } else {
            pricing_plan = orderObject?.order_items?.filter((item) => item?.id == service?.item_details?.id);
        }
        if (pricing_plan[0]?.pricing_plan == "Premium") {
            totalSum += parseFloat(service.premium_price);
        } else if (service.is_standard) {
            totalSum += parseFloat(service.standard_price);
        }
    });
    return totalSum;
}

function CustomerTab({ title, value }) {
    return (
        <div className="order-detail-box">
            <h6>{title}</h6>
            <h4>{value}</h4>
        </div>
    );
}

function Customers({ customer }) {
    let totalPrice = 0;
    let salesTax = 0;
    customer?.orders?.map((orderData) => {
        let totalSum = calculateTotalSum(orderData);
        totalPrice += totalSum;
        salesTax += (10.35 / 100) * totalSum;
    })
    // let salesTax = (10.35 / 100) * totalPrice;
    return (
        <li>
            <Link
                to={{
                    pathname: `/customers/${customer._id}`,
                    state: { customerName: customer?.name, customer: customer, orderCount: customer?.orders?.length, totalAmount: customer?.orders?.length == 0 ? 0 : (totalPrice + salesTax + (5 * customer?.orders?.length)).toFixed(2), orders: customer?.orders }
                }}
                onClick={() => {
                    sessionStorage.setItem('currentCustomer', JSON.stringify({ customerName: customer?.name, customer: customer, orderCount: customer?.orders?.length, totalAmount: customer?.orders?.length == 0 ? 0 : (totalPrice + salesTax + (5 * customer?.orders?.length)).toFixed(2), orders: customer?.orders }));
                }}
                className='order-box flex-box align-center space-between'>
                <CustomerTab title="Customer Name" value={customer?.name} />
                <CustomerTab title="Location" value={customer?.addresses[0]?.city} />
                <CustomerTab title="Total Orders" value={customer?.orders?.length} />
                <CustomerTab title="Total Amount Spent" value={`$${customer?.orders?.length == 0 ? 0 : (totalPrice + salesTax + (5 * customer?.orders?.length)).toFixed(2)}`} />
                <div className="order-cta-box">
                    <img src={ctaArrow} alt="" />
                </div>
            </Link>
        </li>
    );
}


export default Customers