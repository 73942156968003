import { Constant } from "../Constant";

const initialState = {
  allService: [],
  singleService: "",
};

export const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.setService:
      return { ...state, allService: action.payload };
    case Constant.setSingleService:
      return { ...state, singleService: action.payload };
    default:
      return state;
  }
};
